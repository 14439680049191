
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button, Card, Dialog,
    DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip
} from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DataContext } from '../contexts/DataContext';
import FiltersList from './FiltersList';
import PropertyFilter from './PropertyFilter';

const columnIndex = {
    "id": 0,
    "code": 1,
    "name": 2,
    "predictiveDetective": 3,
    "specificMonitoring": 4,
    "category": 5,
    "manualAutomaticSemiautomatic": 6,
    "description": 7,
    "minPeriodicity": 8,
    "sodActivityCode": 9,
    //"sodActivityDescription": 10,
    "riskCode": 10,
    "details": 11,
    "ericaCode": 12
}
export default function SodMatrixPageFilters(props) {
    const {
        state,
        setFilter,
        setPage,
        loadRequests,
        isSearching,
        setHeaderList,
    } = useContext(DataContext);

    const [searchQuery, setSearchQuery] = useState();

    const updateValueGlobal = useCallback(
        debounce((filterUpdates) => {
            setFilter(filterUpdates);
            setPage(0);
        }, 350), []
    );

    const updateValue = (filterUpdates) => {
        setFilter(filterUpdates);
        setPage(0);
    };

    useEffect(() => {
        if (searchQuery || state.filter.searchText)
            updateValueGlobal({ searchText: searchQuery });
    }, [searchQuery]);

    const [open, setOpen] = React.useState(false);

    const handleAddAll = () => {
        let newData = state.actualHeadersList;

        newData.map(h => {
            if (h.filter !== 'blocked' && h.filter === false) {
                h.filter = true;
            }
        })
        setHeaderList(newData);
    };

    const handleRemoveAll = () => {
        let newData = state.actualHeadersList;

        newData.map(h => {
            if (h.filter !== 'blocked' && h.filter === true) {
                h.filter = false;
            }
        })
        setHeaderList(newData);
    };

    const handleClickSearch = () => {
        loadRequests();
    };

    return <>
        <div>
            <Card>
                <CardActions >
                    <TextField
                        variant="standard"
                        // onChange={(e) => setSearchQuery({ searchText: e.target.value })}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        disabled={isSearching}
                        value={searchQuery}
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search Transactions' }}
                        type="search"
                        size="small"
                        sx={{ margin: 'auto', alignItems: 'center', alignContent: 'center', width: '80%' }}
                        fullWidth
                    />
                </CardActions>

                <Collapse in={true} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Grid container GridClassKey="align-items-xs-center" columnSpacing={0} rowSpacing={0}>

                            < PropertyFilter xs={6} updatefilter={updateValue} name={'code'} index={columnIndex.code} optionsFilter={false} />
                            < PropertyFilter xs={6} updatefilter={updateValue} name={'category'} index={columnIndex.category} optionsFilter={false} />
                            < PropertyFilter xs={6} updatefilter={updateValue} name={'predictiveDetective'} index={columnIndex.predictiveDetective} optionsFilter={false} />
                            < PropertyFilter xs={6} updatefilter={updateValue} name={'specificMonitoring'} index={columnIndex.specificMonitoring} optionsFilter={false} />
                            < PropertyFilter xs={6} updatefilter={updateValue} name={'description'} index={columnIndex.description} optionsFilter={false} />
                            < PropertyFilter xs={6} updatefilter={updateValue} name={'sodActivityCode'} index={columnIndex.sodActivityCode} optionsFilter={false} />
                            {/*< PropertyFilter xs={6} updatefilter={updateValue} name={'sodActivityDescription'} index={columnIndex.sodActivityDescription} optionsFilter={false} />*/}
                            < PropertyFilter xs={6} updatefilter={updateValue} name={'riskCode'} index={columnIndex.riskCode} optionsFilter={false} />

                            <Grid item xs={12} textAlign='center'>
                                <Tooltip title="Add Filters" aria-label="add-filters">
                                    <IconButton onClick={() => setOpen(true)} variant='contained'>
                                        <AddIcon />
                                    </IconButton >
                                </Tooltip>
                                <Tooltip title="Search" aria-label="search">
                                    <IconButton onClick={handleClickSearch} variant='contained'>
                                        <SearchIcon />
                                    </IconButton >
                                </Tooltip>
                            </Grid>

                            <Dialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open} maxWidth={'xl'} m>

                                <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}> Add or remove Filters to display </DialogTitle>

                                <DialogContent dividers>
                                    <FiltersList />
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus variant="outlined" onClick={handleRemoveAll} sx={{ color: 'black' }}> Remove all </Button>
                                    <Button autoFocus variant="outlined" onClick={handleAddAll} sx={{ color: 'black' }}> Add all </Button>
                                    <Button autoFocus variant="outlined" onClick={() => setOpen(false)} sx={{ color: 'black' }}> Confirm </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    </>;
}