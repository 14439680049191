export const searchCategories = {
    ActiveDirectory: "AD",
    Sap: "SAP",
    Entities: "ENTITIES",
    AppAndRoles: "APPROLES"
}

export const NavRoutes = {
    SoDDetailPage: (id) => `/soddetail/${id}`,
    SoDDetailPageTemplate: "/soddetail/:id",
    RisksDetailPage: (id) => `/risksdetail/${id}`,
    RisksDetailPageTemplate: "/risksdetail/:id",
    CompensatoryControlsDetailPage: (id) => `/compensatorycontrolsdetail/${id}`,
    CompensatoryControlsDetailPageTemplate: "/compensatorycontrolsdetail/:id",
    CataloguePage: "/",
    Forbidden: "/forbidden",
    SoDMatrixPage: "/sodmatrix",
}

export const ApplicationRoles = {
    Admin: "Admin",
    Operator: "Operator",
    WWIC: "WWIC"
}

export const DefaultValues = {
    DefaultProfilePicture: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSI2MDAiIGhlaWdodD0iNjAwIiBmaWxsPSJ3aGl0ZSI+PHNjcmlwdCB4bWxucz0iIj48L3NjcmlwdD4KCiAgPHRpdGxlPkFic3RyYWN0IHVzZXIgaWNvbjwvdGl0bGU+CgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjaXJjdWxhci1ib3JkZXIiPgogICAgICA8Y2lyY2xlIGN4PSIzMDAiIGN5PSIzMDAiIHI9IjI1MCI+PC9jaXJjbGU+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KCiAgPGNpcmNsZSBjeD0iMzAwIiBjeT0iMzAwIiByPSIyODAiIGZpbGw9IiNjOGM4YzgiPjwvY2lyY2xlPgogIDxjaXJjbGUgY3g9IjMwMCIgY3k9IjIzMCIgcj0iMTAwIj48L2NpcmNsZT4KICA8Y2lyY2xlIGN4PSIzMDAiIGN5PSI1NTAiIHI9IjE5MCIgY2xpcC1wYXRoPSJ1cmwoI2NpcmN1bGFyLWJvcmRlcikiPjwvY2lyY2xlPgo8L3N2Zz4="
}

export const Formats = {
    DateFormat: 'DD-MM-yyyy',
    SapDateFormat: 'DD-MM-yyyy LT',

}

export const TransactionType = [
    'Custom',
    'Standard'
]

export const TransactionReferenceType = [
    'Cockpit',
    'Similar',
    'AuthorityCheck',
    'DisplayOnly',
    'Integrated',
    'Replacing',
    'TreeStructure'
]

export const TransactionSimilarType = [
    'Extensive',
    'Restrictive',
    'N_A'
]

export const TransactionModel = [
    'Custom',
    'Standard',
]

export const TransactionEntityName = [
    'TransactionRequest',
    'Company',
    'Area',
    'Process',
    'SubProcess',
    'CompensatoryControl',
    'SodActivity',
    'SensitiveActivity',
    'Risk',
    'CompensatoryControlRisk',
    'Application',
]

export const EditFiedDataType = {
    Boolean: "Boolean",
    Options: "Options",
    MultiOptions: "MultiOptions",
    TextField: "TextField",
    StaticOptions: "StaticOptions",
    StaticMultiOptions: "StaticMultiOptions",
}