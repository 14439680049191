import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from "react";
import { useParams } from "react-router";
import { DataContext } from '../../contexts/DataContext';
import { UserContext } from '../../contexts/UserContext';
import { AuthPlatformTableNames } from '../../helpers';
import { DependencyList } from '../../ui-components/DependencyList';
import DetailToolbarEdit from '../../ui-components/DetailToolbarEdit';
import { KeyValueList } from '../../ui-components/KeyValueList';
import { deepClone, TransactionEntityName, ApplicationRoles } from '../../utils';

export const SoDDetailPage = (props) => {

    const { userState, setActualSection, isUserInRole } = useContext(UserContext);
    const {
        state,
        loadSodActivities,
        isLoadingSoDObjectDependencies,
        cleanSoDObjectDependencies,
        loadSoDObjectDependencies,
        isLoadingOptions,
        loadOptions,
        resolveOption
    } = useContext(DataContext);

    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);

    if (!isLoadingOptions && (state.options.module?.length === 0 || state.options.process?.length === 0))
        loadOptions('SodActivitiesGridOptions');

    React.useEffect(() => {
        let active = true;
        (
            async () => {
                setLoading(true);

                await loadSodActivities(state.filterSod);

                if (!active) { return; }
                setLoading(false);
            }
        )();
        return () => { active = false; };
    }, []);

    React.useEffect(() => {
        (
            async () => {
                const entityName = TransactionEntityName.indexOf('SodActivity');
                await loadSoDObjectDependencies(id, entityName);
            }
        )();
        return cleanSoDObjectDependencies;
    }, [id])



    const sodActivity = state.sodActivities?.find(el => el.id === Number(id));

    if (userState.actualSection !== 'SoDDetailPage')
        setActualSection('SoDDetailPage');

    const dependencies = state.sodObjectDependencies;

    const riskDeps = dependencies.filter(x => x.type === 'Risk');
    const ccDeps = dependencies.filter(x => x.type === 'CompensatoryControl');


    const memoizedSodActivity = React.useMemo(() => {
        if (!sodActivity) return;
        const sodActivity1 = deepClone(sodActivity);
        sodActivity1.area = resolveOption(AuthPlatformTableNames.Area, sodActivity.area) ?? '';
        sodActivity1.process = resolveOption(AuthPlatformTableNames.Process, sodActivity.process) ?? '';
        sodActivity1.linkedToHighPriorityRisk = sodActivity.grcRiskId ? 'Yes' : 'No';

        return sodActivity1;
    }, [sodActivity]);

    return (
        sodActivity ?
            <>
                <Typography variant="h5" gutterBottom component="div" align="center" paddingTop={5}>
                    SoD Activity  {memoizedSodActivity.Code}
                </Typography>

                <Box sx={{ mx: { xs: 5, md: 15, lg: 25, xl: 40 }, my: 3, marginBottom: 0 }}>
                    <Grid container spacing={4} sx={{ marginBottom: '60px' }}>
                        {
                            isUserInRole(ApplicationRoles.Admin) &&
                            <Grid item xs={12}>
                                <DetailToolbarEdit id={id} {...props} />
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <KeyValueList
                                title="SoD Activity details"
                                data={memoizedSodActivity}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', padding: 10 }}
                                component={Paper}
                                labels={[
                                    { property: "code", label: "SoD Activity Code" },
                                    { property: "description", label: "SoD Activity Description" },
                                    { property: "italianDescription", label: "Italian Description" },
                                ]}
                            />
                            <KeyValueList
                                data={memoizedSodActivity}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', padding: 10, marginTop: 30 }}
                                component={Paper}
                                labels={[
                                    { property: "area", label: "Area" },
                                    { property: "linkedToHighPriorityRisk", label: " Linked to High Priority Risk" },
                                    { property: "sys", label: "System" },
                                    { property: "grcRiskId", label: "Grc Risk Id" },
                                    { property: "note", label: "Note" },
                                ]}
                            />
                        </Grid>

                        {!isLoadingSoDObjectDependencies && dependencies && dependencies.length > 0 &&
                            <Grid item xs={12} md={6} spacing={1} sx={{ position: 'relative', }}>
                                <Grid container rowSpacing={2}>
                                    {riskDeps && riskDeps.lenth > 0 &&
                                        <Grid item xs={12}>
                                            <DependencyList
                                                dependencies={riskDeps}
                                                title='Risk dependencies of this item'
                                                urlSegment='risksdetail'
                                            />
                                        </Grid>}
                                    {ccDeps && ccDeps.lenth > 0 &&
                                        <Grid item xs={12} sx={{ position: 'absolute', bottom: '0', right: '0', left: '0', paddingLeft: '15px', }}>
                                            <DependencyList
                                                dependencies={ccDeps}
                                                title='Compensatory Control dependencies of this item'
                                                urlSegment='compensatorycontrolsdetail'
                                            />
                                        </Grid>}
                                </Grid>
                            </Grid>}
                    </Grid>
                </Box>
            </>
            :
            <></>
    );
}