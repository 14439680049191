import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import React from "react";
import DetailToolbar from './DetailToolbar';

export default function DetailToolbarEdit(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <Grid container alignContent="flex-start" >
                <Grid item xl={0.5} md={0.5} xs={0.5} >
                    <Tooltip title="Edit" aria-label="edit">
                        <IconButton onClick={handleClickOpen} variant='contained'>
                            <ModeEditIcon />
                        </IconButton >
                    </Tooltip>
                    <DetailToolbar open={open} handleClose={() => setOpen(false)} {...props} />
                </Grid>
            </Grid>
        </div>
    );
}