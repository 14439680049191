import React, { useContext } from "react";
import {
    Grid,
    Button,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    IconButton,
    Tooltip,
    TextField,
} from "@mui/material";
import ColumnsList from './ColumnsList';
import GetAppIcon from '@mui/icons-material/GetApp';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataContext } from '../contexts/DataContext';
import { UserContext } from '../contexts/UserContext';
import { grey } from '@mui/material/colors';
import debounce from "lodash.debounce";
import DetailToolbarAdd from "./DetailToolbarAdd";
import { ApplicationRoles } from "../utils";

export default function DatagridToolbar(props) {

    const {
        state,
        setHeaderList,
        setSodActivitiesHeaderList,
        setCompensatoryControlsHeaderList,
        setSoDRisksHeaderList,
        isExporting,
        setHiddenRows,
    } = useContext(DataContext);

    const {
        isUserInRole,
        isUserInAnyRole
    } = useContext(UserContext);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemoveAll = () => {
        let newData;
        switch (props.dataGrid) {
            case 'SoDMatrix':
                newData = state.actualHeadersList;
                setHeaderList(removeAction(newData));
                break;
            case 'SodActivities':
                newData = state.actualSodActivitiesHeadersList;
                setSodActivitiesHeaderList(removeAction(newData));
                break;
            case 'CompensatoryControls':
                newData = state.actualCompensatoryControlsHeadersList;
                setCompensatoryControlsHeaderList(removeAction(newData));
                break;
            case 'Risks':
                newData = state.actualSoDRisksHeadersList;
                setSoDRisksHeaderList(removeAction(newData));
                break;
            default: throw new Error("Case not supported");
        }
    };

    const removeAction = (newData) => {
        newData.map(h => {
            if (h.hidden !== 'blocked' && h.hidden === false) {
                h.hidden = true;
                h.columnsOrder = -1;
            }
        })
        return newData;
    }

    const handleAddAll = () => {
        let newData;
        switch (props.dataGrid) {
            case 'SoDMatrix':
                newData = state.actualHeadersList;
                setHeaderList(addAction(newData));
                break;
            case 'SodActivities':
                newData = state.actualSodActivitiesHeadersList;
                setSodActivitiesHeaderList(addAction(newData));
                break;
            case 'CompensatoryControls':
                newData = state.actualCompensatoryControlsHeadersList;
                setCompensatoryControlsHeaderList(addAction(newData));
                break;
            case 'Risks':
                newData = state.actualSoDRisksHeadersList;
                setSoDRisksHeaderList(addAction(newData));
                break;
            default: throw new Error("Case not supported");

        }
    };

    const addAction = (newData) => {
        newData.map(h => {
            if (h.hidden !== 'blocked' && h.hidden === true) {
                h.hidden = false;
                h.columnsOrder = Math.max(...newData.map(h => h.columnsOrder)) + 1;
            }
        })
        return newData;
    }

    const debouncedGlobalSearch = React.useCallback(
        debounce((text) => {
            const query = text ?? null;
            return props.handleGlobalSearch(query);
        }, 350),
        []
    );

    function hasUserCreatePermission() {
        if (props.dataGrid !== 'CompensatoryControls')
            return isUserInRole(ApplicationRoles.Admin);
        return isUserInAnyRole([ApplicationRoles.Admin, ApplicationRoles.WWIC]);
    }

    return (
        <div>
            <Grid container alignItems="center">
                <Grid item xl={0.2} md={0.2} xs={0.2} />
                <Grid item xl={0.5} md={0.5} xs={0.5} >
                    <Tooltip title="Download export" aria-label="download-export">
                        <span>
                            <IconButton
                                disabled={isExporting || props.loading}
                                onClick={props.onDownload}
                                color="primary"
                                aria-label="export"
                                variant="outlined"
                                size="large">
                                <GetAppIcon sx={{ color: isExporting || props.loading ? grey[220] : grey[500] }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xl={0.5} md={0.5} xs={0.5} >
                    <Tooltip title="Add Columns" aria-label="advanced-filters">
                        <IconButton
                            onClick={handleClickOpen}
                            color="primary"
                            aria-label="toggle-filter-list"
                            variant="outlined"
                            size="large">
                            <SettingsIcon sx={{ color: grey[500] }} />
                        </IconButton>
                    </Tooltip>

                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'xl'}>

                        <DialogTitle id="customized-dialog-title" onClose={handleClose}> Add or remove Columns to display </DialogTitle>
                        <DialogContent dividers> <ColumnsList {...props} /> </DialogContent>
                        <DialogActions>
                            <Button autoFocus variant="outlined" onClick={handleRemoveAll} sx={{ color: 'black' }}>
                                Remove all
                            </Button>
                            <Button autoFocus variant="outlined" onClick={handleAddAll} sx={{ color: 'black' }}>
                                Add all
                            </Button>
                            <Button autoFocus variant="outlined" onClick={handleClose} sx={{ color: 'black' }}>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                {props.dataGrid !== 'SoDMatrix' && hasUserCreatePermission() &&
                    <Grid item xl={0.5} md={0.5} xs={0.5}>
                        <DetailToolbarAdd id={0} {...props} />
                    </Grid>}
                {
                    state.hiddenRows ?
                        <Grid item xl={0.5} md={0.5} xs={0.5}>
                            < Tooltip title="Reset hidden rows" aria-label="Reset-hidden-rows">
                                <IconButton
                                    onClick={() => setHiddenRows(false)}
                                    color="primary"
                                    aria-label="Reset-hidden-rows"
                                    variant="outlined"
                                    size="large">
                                    <VisibilityIcon sx={{ color: grey[500] }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        : undefined
                }
                {props.handleGlobalSearch &&
                    <Grid item xs={6}>
                        <TextField
                            variant="standard"
                            onChange={(e) => debouncedGlobalSearch(e.target.value)}
                            disabled={props.loading}
                            placeholder="Search"
                            type="search"
                            size="small"
                            fullWidth
                        />
                    </Grid>}
            </Grid>
        </div>
    );
}