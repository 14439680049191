import {
    Button, Card, Dialog, DialogContent,
    DialogTitle, Grid,
} from "@mui/material";
import React, { useContext } from "react";
import { DataContext } from '../contexts/DataContext';
import EditField from './EditField';
import { deepClone, EditFiedDataType } from "../utils";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useAggregatedState } from '../helpers';

export default function DetailToolbar(props) {
    const {
        state,
        loadExport,
        updateSodActivity,
        updateCompensatoryControls,
        updateRisk,
        isLoadingOptions,
        addSodActivity,
        addCompensatoryControl,
        addRisk,
        loadSodActivities,
        loadSoDRisks,
        loadCompensatoryControls,
    } = useContext(DataContext);

    const getExportHandler = (id) => {
        loadExport(id);
    };

    var newData;
    var pageTitle;
    var listRender;
    const isNewItem = () => !props.id && props.newItem;
    const filteredRiskForCompensatoryControl = [];

    //const [isSodActivitySelected, setIsSodActivitySelected] = useState([]);

    const renderEditFields = () => {
        switch (props.dataGrid) {
            case 'SoDMatrix':
                break;
            case 'SodActivities':
                pageTitle = isNewItem() ? 'Create new SoD Activity' : 'Edit SoD Activity Detail';
                if (!newData)
                    newData = deepClone(state.sodActivities);
                listRender = () => renderSoD();
                break;
            case 'CompensatoryControls':
                pageTitle = isNewItem() ? 'Create new Compensatory Control' : 'Edit Compensatory Control Detail';

                if (!newData)
                    newData = deepClone(state.compensatoryControls);
                listRender = () => renderCompensatoryControls();
                break;
            case 'Risks':
                pageTitle = isNewItem() ? 'Create new SoD Risk' : 'Edit SoD Risk Detail';

                if (!newData)
                    newData = deepClone(state.soDRisks);
                listRender = () => renderRisks();
                break;
            default:
                throw new Error('Case not handled');
        }
    }
    renderEditFields();

    const objIndex = newData.findIndex(el => el.id == props.id);

    const [item, setItem] = useAggregatedState(isNewItem() ? {} : newData[objIndex]);

    function renderSoD() {
        const data = newData.filter(el => el.id == props.id);
        return (
            <>
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"code"}                      {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"description"}               {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.Options} data={data} setNewValue={setNewValue} fieldName={"area"}                      {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"sys"}                       {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"italianDescription"}        {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"grcRiskId"}                 {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"note"}                      {...props} />
            </>
        );
    }

    function renderCompensatoryControls() {
        const data = newData.filter(el => el.id == props.id);
        return (
            <>
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"code"}                       {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"minPeriodicity"}             {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"name"}                       {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"ericaCode"}                  {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.MultiOptions} data={data} setNewValue={setNewValue} fieldName={"sodActivities"}
                    //setIsSodActivitySelected={setIsSodActivitySelected}
                    {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.StaticOptions} data={data} setNewValue={setNewValue} fieldName={"predictiveDetective"}    {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.MultiOptions} data={data} setNewValue={setNewValue} fieldName={"risks"} item={item}
                    // isSodActivitySelected={isSodActivitySelected}
                    {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.StaticMultiOptions} data={data} setNewValue={setNewValue} fieldName={"specificMonitoring"}           {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"category"}                     {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.StaticMultiOptions} data={data} setNewValue={setNewValue} fieldName={"manualAutomaticSemiautomatic"} {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"details"}                         {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"description"}                  {...props} />
            </>
        );
    }

    function renderRisks() {
        const data = newData.filter(el => el.id == props.id);
        return (
            <>
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"code"}  {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"impact"}               {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.MultiOptions} data={data} setNewValue={setNewValue} fieldName={"areas"}                {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"impactApplication"}    {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.Options} data={data} setNewValue={setNewValue} fieldName={"firstSodActivity"}     {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.StaticOptions} data={data} setNewValue={setNewValue} fieldName={"criticality"}          {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.Options} data={data} setNewValue={setNewValue} fieldName={"secondSodActivity"}    {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"grcRiskId"}            {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"italianDescription"}   {...props} />
                < EditField xs={6} required={true} dataType={EditFiedDataType.TextField} data={data} setNewValue={setNewValue} fieldName={"description"}          {...props} />
            </>
        );
    }

    const handleSave = async () => {
        switch (props.dataGrid) {
            case 'SodActivities':
                if (isNewItem()) {
                    await addSodActivity({
                        ...item,
                        process: item.process?.value ?? item.process,
                        area: item.area?.value ?? item.area
                    });
                } else {
                    await updateSodActivity({
                        ...item,
                        process: item.process?.value ?? item.process,
                        area: item.area?.value ?? item.area
                    });
                }
                loadSodActivities();
                break;
            case 'CompensatoryControls':
                if (isNewItem()) {
                    await addCompensatoryControl({
                        ...item,
                        sodActivities: item.sodActivities?.map(x => handleStructure(x)) ?? item.sodActivities,
                        risks: item.risks?.map(x => handleStructure(x)) ?? item.risks
                    });
                } else {
                    await updateCompensatoryControls({
                        ...item,
                        sodActivities: item.sodActivities?.map(x => handleStructure(x)) ?? item.sodActivities,
                        risks: item.risks?.map(x => handleStructure(x)) ?? item.risks
                    });
                }
                loadCompensatoryControls();
                break;
            case 'Risks':
                if (isNewItem()) {
                    await addRisk({
                        ...item,
                        areas: item.areas?.map(x => handleStructure(x)) ?? item.areas,
                        firstSodActivity: item.firstSodActivity?.value ?? item.firstSodActivity,
                        secondSodActivity: item.secondSodActivity?.value ?? item.secondSodActivity
                    });
                } else {
                    await updateRisk({
                        ...item,
                        areas: item.areas?.map(x => handleStructure(x)) ?? item.areas,
                        firstSodActivity: item.firstSodActivity?.value ?? item.firstSodActivity,
                        secondSodActivity: item.secondSodActivity?.value ?? item.secondSodActivity
                    });
                }
                loadSoDRisks();
                break;
            default:
                throw new Error('Case not handled');
        }
        props.handleClose();
    };

    const setNewValue = (name, value) => {
        if (!isNewItem()) {
            // reuse old value to sync item state
            newData[objIndex][name] = value;
        }
        //if (name === 'sodActivities') {
        //    setIsSodActivitySelected(value);
        //}
        setItem({
            ...item,
            [name]: value
        });
    }

    const handleStructure = (option) => {
        if (Number.isInteger(option)) {
            return option;
        } else {
            return option.value;
        }
    }

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open} maxWidth={'xl'} m>

            <DialogTitle id="customized-dialog-title" onClose={props.handleClose} align="center">
                {pageTitle}
            </DialogTitle>

            <DialogContent loa dividers>
                {isLoadingOptions ?

                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <div>
                        <Card>
                            <Grid container GridClassKey="align-items-xs-center" columnSpacing={0} rowSpacing={0} sx={{ marginTop: '20px', marginBottom: '20px' }} >
                                {listRender()}
                            </Grid>
                        </Card>
                        <Grid item xs={12} sx={{ marginTop: '40px' }} textAlign='center'>
                            <Button autoFocus variant="outlined" onClick={handleSave} sx={{ color: 'black' }}>Save</Button >
                            <Button autoFocus variant="outlined" onClick={props.handleClose} sx={{ color: 'black' }}>Cancel</Button>
                        </Grid>
                    </div>
                }
            </DialogContent>
        </Dialog>
    );
}