import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
import CompensatoryControlsDataGrid from "../ui-components/dataGrid/CompensatoryControlsDataGrid";
import SoDActivitiesDataGrid from "../ui-components/dataGrid/SoDActivitiesDataGrid";
import SoDRisksDataGrid from "../ui-components/dataGrid/SoDRisksDataGrid";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `action-tab-${index}`,
        'aria-controls': `action-tabpanel-${index}`,
    };
}

export default function CatalogueSelections(props) {

    const {
        pageState,
        state,
        loadOptions,
        isLoadingOptions,
        setSelectedTab,
    } = useContext(DataContext);

    var tabValue = !pageState.selectedTab || pageState.selectedTab === 'SodActivities' ? 0 : (pageState.selectedTab === 'SoDRisks' ? 1 : 2);

    const [value, setValue] = React.useState(tabValue) ;

    if (!isLoadingOptions && (state.options.module?.length === 0 || state.options.process?.length === 0))
        loadOptions('SodActivitiesGridOptions');

    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                if (!isLoadingOptions && (state.options.module?.length === 0 || state.options.process?.length === 0))
                    loadOptions('SodActivitiesGridOptions');
                setSelectedTab('SodActivities');
                break;
            case 1:
                if (!isLoadingOptions && (state.options.module?.length === 0 || state.options.sodActivity?.length === 0))
                    loadOptions('RisksGridOptions');
                setSelectedTab('SoDRisks');
                break;
            case 2:
                if (!isLoadingOptions && (state.options.risk?.length === 0 || state.options.sodActivity?.length === 0))
                    loadOptions('CompensatoryControlsGridOptions');
                setSelectedTab('CompensatoryControls');
                break;
        }
        setValue(newValue);
    };

    return (
        <Box
            sx={{position: 'relative'}}
        >
            <AppBar color="default" sx={{
                width: 500,
                position: 'relative',
                margin:'auto'
            }} >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                >
                    <Tab label="SoD Activities" {...a11yProps(0)} />
                    <Tab label="SoD Risks" {...a11yProps(1)} />
                    <Tab label="Compensatory Controls" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} >
                <SoDActivitiesDataGrid />
            </TabPanel>
            <TabPanel value={value} index={1} >
                <SoDRisksDataGrid />
            </TabPanel>
            <TabPanel value={value} index={2}
            >
                <CompensatoryControlsDataGrid />
            </TabPanel>
        </Box >
    );
}