import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from "react";
import { useParams } from "react-router";
import { DataContext } from '../../contexts/DataContext';
import { UserContext } from '../../contexts/UserContext';
import { AuthPlatformTableNames, ComplexEntityNames } from '../../helpers';
import { DependencyList } from '../../ui-components/DependencyList';
import DetailToolbar from '../../ui-components/DetailToolbar';
import DetailToolbarEdit from '../../ui-components/DetailToolbarEdit';
import { KeyValueList } from '../../ui-components/KeyValueList';
import { ApplicationRoles, deepClone, TransactionEntityName } from '../../utils';

export const RisksDetailPage = (props) => {
    const {
        state,
        loadSoDRisks,
        isLoadingSoDObjectDependencies,
        cleanSoDObjectDependencies,
        loadSoDObjectDependencies,
        resolveOption,
        resolveOptions,
        isLoadingOptions,
        loadOptions,
        loadComplexOptions
    } = useContext(DataContext);
    const { userState, setActualSection, isUserInRole } = useContext(UserContext);

    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);

    if (!isLoadingOptions && (state.options.module?.length === 0 || state.options.sodActivity?.length === 0))
        loadOptions('RisksGridOptions');

    React.useEffect(() => {
        let active = true;
        (
            async () => {
                setLoading(true);
                await loadSoDRisks(state.filterRisks);
                if (!active) { return; }
                setLoading(false);
            }
        )();
        return () => { active = false; };
    }, []);

    React.useEffect(() => {
        (
            async () => {
                const entityName = TransactionEntityName.indexOf('Risk');
                await loadSoDObjectDependencies(id, entityName);
            }
        )();
        return cleanSoDObjectDependencies;
    }, [id])

    React.useEffect(() => {
        loadComplexOptions(
            ComplexEntityNames.SodActivity
        )
    }, [])

    const risk = state.soDRisks?.find(el => el.id === Number(id));

    if (userState.actualSection !== 'RisksDetailPage')
        setActualSection('RisksDetailPage');

    const memoizedRisk = React.useMemo(() => {
        if (!risk) return;
        const risk1 = deepClone(risk);
        risk1.firstSodActivity = resolveOption(ComplexEntityNames.SodActivity, risk.firstSodActivity) ?? '';
        risk1.secondSodActivity = resolveOption(ComplexEntityNames.SodActivity, risk.secondSodActivity) ?? '';
        risk1.areas = resolveOptions(AuthPlatformTableNames.Area, risk.areas);
        return risk1;
    }, [risk]);

    const dependencies = state.sodObjectDependencies;
    return (
        memoizedRisk ?
            <>
                <Typography variant="h5" gutterBottom component="div" align="center" paddingTop={5}>
                    Risks Details
                </Typography>

                <Box sx={{ mx: { xs: 5, md: 15, lg: 25, xl: 40 }, my: 3, marginBottom: 0 }}>
                    <Grid container spacing={4} sx={{ marginBottom: '60px' }}>
                        {
                            isUserInRole(ApplicationRoles.Admin) &&
                            <Grid item xs={12}>
                                <DetailToolbarEdit id={id} {...props} />
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <KeyValueList
                                title="SoD Risk details"
                                data={memoizedRisk}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', padding: 10 }}
                                component={Paper}
                                labels={[
                                    { property: "code", label: "SoD Risk Code" },
                                    { property: "description", label: "SoD Risk Description" },
                                    { property: "italianDescription", label: "Italian Description" },
                                ]}
                            />
                            <KeyValueList
                                data={memoizedRisk}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', padding: 10, marginTop: 30 }}
                                component={Paper}
                                labels={[
                                    { property: "firstSodActivity", label: "SoD Activity 1" },
                                    { property: "secondSodActivity", label: "SoD Activity 2" },
                                    { property: "areas", label: "Areas" },
                                    { property: "impact", label: "Impact" },
                                    { property: "impactApplication", label: "Impact Applications" },
                                    { property: "criticality", label: "Priority" },
                                    { property: "grcRiskId", label: "Grc Risk Id" },
                                ]}
                            />
                        </Grid>

                        {!isLoadingSoDObjectDependencies && dependencies && dependencies.length > 0 &&
                            <Grid item xs={12} md={6} spacing={1} sx={{ position: 'relative', }}>
                                <Grid container rowSpacing={2}>
                                    <Grid item xs={12}>
                                        <DependencyList
                                            dependencies={dependencies.filter(x => x.type === 'CompensatoryControl')}
                                            title='Compensatory Control dependencies of this item'
                                            urlSegment='compensatorycontrolsdetail'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>}
                    </Grid>
                </Box>
            </>
            :
            <></>
    );
}