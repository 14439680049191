/* eslint-disable default-case */
import ArticleIcon from '@mui/icons-material/Article';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Box, IconButton
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { DataContext } from '../../contexts/DataContext';
import { UserContext } from '../../contexts/UserContext';
import { AuthPlatformTableNames } from '../../helpers';
import { NavRoutes, downloadExcelFromDatagrid, copyToClipboard, doSimplifiedFuzzySeach, ApplicationRoles } from "../../utils";
import DatagridToolbar from '../DatagridToolbar';
import DeleteWithConfirm from '../DeleteWithConfirm';
import { StyledDataGrid, StyledTooltip } from '../StyledTableComponents';

function getRender(field, row, optionResolver) {
    let text;
    let fragment = '';
    let txtToCopy;
    switch (field) {
        case "code":
            text = row.code;
            break;
        case "description":
            text = row.description;
            break;
        case "area":
            text = optionResolver(AuthPlatformTableNames.Area, row.area);
            break;
        case "linkedToHighPriorityRisk":
            text = row.linkedToHighPriorityRisk;
            break;
        case "sys":
            text = row.sys;
            break;
        case "italianDescription":
            text = row.italianDescription;
            break;
        case "grcRiskId":
            text = row.grcRiskId;
            break;
        case "note":
            text = row.note;
            break;
    }

    handleFragment();

    return {
        text,
        cell: (
            <StyledTooltip title={
                <>
                    {text}
                    <IconButton
                        onClick={() => copyToClipboard(txtToCopy ? txtToCopy : text)}
                        variant='contained'
                        size='small'>
                        <ContentCopyIcon fontSize='small' />
                    </IconButton >
                </>
            }
            // arrow style={{ whiteSpace: 'pre', }}
            >
                <span style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                    {
                        fragment ? fragment : text
                    }

                </span>
            </StyledTooltip>
        )
    };

    function handleFragment() {
        if (text) {
            fragment =
                <div style={{ whiteSpace: 'normal', display: "-webkit-box", '-webkit-line-clamp': '3', '-webkit-box-orient': 'vertical', overflow: "hidden" }}>
                    {text}
                </div>;
        }
    }
}

export default function SoDActivitiesDataGrid(props) {
    const {
        state,
        loadSodActivities,
        isLoadingSodActivities,
        isDeleting,
        deleteSodActivity,
        setSelectedTab,
        pageState,
        resolveOption
    } = useContext(DataContext);

    const { userState, setActualSection, isUserInRole } = useContext(UserContext);
    const [query, setQuery] = React.useState();

    const memoizedColumns = React.useMemo(() => {
        let columns = [];

        columns.push({
            headerName: 'TCode Card',
            field: "click",
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        {isUserInRole(ApplicationRoles.Admin) &&
                            <StyledTooltip title="Delete item" aria-label="delete-item" >
                                <span>
                                    <DeleteWithConfirm
                                        loading={isDeleting}
                                        handleDelete={() => deleteSodActivity(params.row.id)}
                                        onDeleted={() => loadSodActivities()} />
                                </span>
                            </StyledTooltip>}
                        <StyledTooltip title="View Detail" aria-label="view-detail" >
                            <IconButton
                                onClick={() => {
                                    if (userState.actualSection !== 'SoDDetailPage')
                                        setActualSection('SoDDetailPage');
                                    if (pageState.selectedTab !== 'SodActivities')
                                        setSelectedTab('SodActivities');
                                    history.push(NavRoutes.SoDDetailPage(params.row.id));
                                }}
                                variant='contained'>
                                <ArticleIcon />
                            </IconButton >
                        </StyledTooltip>
                    </>);
            }
        });

        state.actualSodActivitiesHeadersList.sort((a, b) => a.columnsOrder - b.columnsOrder).map(head => (
            columns.push({
                id: head.key,
                headerName: head.headerName,
                field: head.fieldName,
                description: head.headerName,
                headerAlign: head.headerAlign,
                flex: head.flex,
                minWidth: head.minWidth,
                hideable: true,
                hide: head.hidden,
                groupable: false,
                Tooltip,
                renderCell: (params) => (
                    getRender(params.colDef.field, params.row, resolveOption).cell
                ),
            })
        ))
        return (columns);
    }, [state.actualSodActivitiesHeadersList, pageState.selectedTab, setActualSection, setSelectedTab, userState.actualSection]);

    const memoizedRows = React.useMemo(() => {
        let rows = [];
        if (state.sodActivities !== undefined) {
            state.sodActivities.forEach(t => {
                const o = {};
                Object.defineProperty(o, 'internalId', {
                    value: t['id'],
                    writable: false,
                    enumerable: true
                });

                state.actualSodActivitiesHeadersList.forEach((head) => {
                    if (head.fieldName === 'code' && t[head.fieldName] !== null) {
                        Object.defineProperty(o, head.fieldName, {
                            value: t[head.fieldName] + ' - ' + t['description'],
                            writable: false,
                            enumerable: true
                        });
                    } else {
                        Object.defineProperty(o, head.fieldName, {
                            value: t[head.fieldName],
                            writable: true,
                            enumerable: true
                        });
                    }
                })

                o.linkedToHighPriorityRisk = o.grcRiskId ? 'Yes' : 'No'

                rows.push(o);
            });
        }

        return query ? doSimplifiedFuzzySeach(query, rows, {
            threshold: -5000, // don't return bad results
            keys: [
                'description',
                'code',
                'grcRiskId',
                'italianDescription',
                'longDescription',
                'lvmhSodActivityReference',
                'note',
                'sys'
            ]
        }) : rows;
    }, [state.sodActivities, state.actualSodActivitiesHeadersList, query]);

    React.useEffect(() => {
        (
            async () => {
                await loadSodActivities(state.filterSod);
            }
        )();
    }, []);

    const history = useHistory();

    return (
        <>
            <DatagridToolbar
                dataGrid='SodActivities'
                handleGlobalSearch={(query) => setQuery(query)}
                onDownload={() =>
                    downloadExcelFromDatagrid(
                        memoizedRows,
                        memoizedColumns.filter(x => x.field !== 'click'),
                        'SoD Activities',
                        (col, row) => getRender(col, row, resolveOption))}
                loading={isLoadingSodActivities}
                {...props} />
            <Box
                sx={{
                    height: 600,
                    '& .super-app-them  e--header': {
                        backgroundColor: '#787878',
                    },
                }}
            >
                <StyledDataGrid style={{ height: 600, width: '100%' }}
                    columns={memoizedColumns}
                    rows={memoizedRows}
                    rowsPerPageOptions={[100, 200, 300]}
                    disableExtendRowFullWidth={false}
                    getRowId={(row) => row.internalId}
                    density={'compact'}
                    headerHeight={50}
                    rowHeight={90}
                    disableColumnMenu={true}
                    autoPageSize={false}
                    hideFooterPagination={false}
                    hideFooter={false}
                    rowCount={memoizedRows.length}
                    loading={isLoadingSodActivities}
                    disableSelectionOnClick
                />
            </Box>
        </>
    );
}
