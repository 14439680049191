import { utils as excelUtils, writeFile } from 'xlsx';

export function downloadExcelFromDatagrid(data, columns, fileName, render) {
    // preparation
    const rows = formatForDownload(data, columns, render)
    // sort headers base on how properties are listed in the rows
    const sortingArray = Object.keys(rows[0])
    const headers = columns
        .sort((a, b) => sortingArray.indexOf(a.field) - sortingArray.indexOf(b.field))
        .map(x => x.headerName);

    // generate worksheet and workbook
    const worksheet = excelUtils.json_to_sheet(rows);
    const workbook = excelUtils.book_new();
    excelUtils.book_append_sheet(workbook, worksheet, "sheet1");

    // fix headers
    excelUtils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

    // calculate column width
    const max_width = rows.reduce((w, v) => Math.max(w, v.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];

    console.log('HEADERS: ' + JSON.stringify(headers))
    console.log('ROWS: ' + JSON.stringify(Object.keys(rows[0])))

    // create an XLSX file and try to save it
    writeFile(workbook, `${fileName}.xlsx`);
};

function formatForDownload(rows, columns, render) {
    return rows.map(x => {
        let formattedFields = [];
        Object.keys(x).forEach(p => {
            const column = columns.find(c => c.field === p);
            if (column)
                formattedFields.push([
                    column.field,
                    p === 'id' ? x.id : clean(render(column.field, x).text)
                ]);
        });
        return Object.fromEntries(formattedFields);
    });
}

function clean(value) {
    if (!value || value.constructor === String)
        return value;

    if (value.constructor === Array)
        return value.map(x => clean(x)).filter(x => !!x).join('; ');

    if (value.constructor === Object)
        return undefined;
}