import {
    Box, Typography
} from "@mui/material";
import { default as React, useContext } from "react";
import CatalogueSelections from "../ui-components/CatalogueSelections";
import { DataContext } from '../contexts/DataContext';

export const CataloguePage = (props) => {
    const {
        pageState,
        setSelectedTab,
    } = useContext(DataContext);

    if (!pageState.selectedTab && pageState.selectedTab !== null)
        setSelectedTab('SoDRisks');

    return (
        <Box sx={{ mx: 2 }}>
            <Typography variant="h5" gutterBottom component="div" align="center" paddingTop={5}>
                SoD Matrix
            </Typography>
            <Box sx={{ mx: 2, my: 3 }}>
                <CatalogueSelections />
            </Box>
        </Box>
    );
}