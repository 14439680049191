import React, { useContext, useCallback, useState } from 'react';
import { Grid, IconButton, Switch, TextField, Autocomplete } from "@mui/material";
import { DataContext } from '../contexts/DataContext';
import { styled } from '@mui/material/styles';
import { EditFiedDataType } from "../utils";
import debounce from 'lodash.debounce';
import { useAggregatedState } from '../helpers';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const MenuProps = {
    PaperProps: {
        style: {
            textAlign: 'left',
            alignItems: 'left',
        },
    },
};

export default function EditField(props) {
    const {
        state
    } = useContext(DataContext);

    const id = props.id;
    var actualList;
    var options = [];
    var filteredOptions = [];
    var sV;
    const [actualValue, setActualValue] = useAggregatedState(props.data[0]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [checked, setChecked] = useState(false);

    switch (props.dataGrid) {
        case 'SodActivities':
            actualList = state.actualSodActivitiesHeadersList;
            break;
        case 'Risks':
            actualList = state.actualSoDRisksHeadersList;
            break;
        case 'CompensatoryControls':
            actualList = state.actualCompensatoryControlsHeadersList;
            break;
    }
    const isNewItem = () => !props.id && props.newItem;

    const updateOptionsValue = (val, opts) => {
        const lowerName = name.charAt(0).toLowerCase() + name.slice(1);
        let o = opts;
        let selectedOption;
        if (o !== null && o !== undefined) {
            if (props.dataType === EditFiedDataType.StaticMultiOptions) {
                let newOpt = [];
                selectedOption = '';
                o.forEach((sV) => {
                    if (newOpt.findIndex(opt => opt.key === sV.key) === -1)
                        newOpt.push(sV);
                    let tmpArr = selectedOption.split('/');
                    if (!tmpArr.includes(sV.key))
                        selectedOption = selectedOption + (selectedOption !== '' ? '/' : '') + sV.key;
                });
                o = newOpt;
            } else if (props.dataType === EditFiedDataType.StaticOptions) {
                selectedOption = o.key;
            } else if (props.dataType === EditFiedDataType.Options) {
                selectedOption = {
                    tableName: props.fieldName.charAt(0).toUpperCase() + props.fieldName.slice(1),
                    tableType: props.fieldName.charAt(0).toUpperCase() + props.fieldName.slice(1),
                    value: o.key,
                };
            } else {
                selectedOption = [];
                o.forEach((sV) => {
                    selectedOption.push({
                        tableName: props.fieldName.charAt(0).toUpperCase() + props.fieldName.slice(1),
                        tableType: props.fieldName.charAt(0).toUpperCase() + props.fieldName.slice(1),
                        value: sV.key,
                    });
                });
            }
            setActualValue({ [lowerName]: o });
            setSelectedValues(o);
            sV = o;
        } else {
            if (props.dataType === EditFiedDataType.StaticMultiOption || props.dataType === EditFiedDataType.StaticOptions) {
                selectedOption = '';
            }
            else {
                selectedOption = [];
            }
        }
        props.setNewValue(lowerName, selectedOption);
        //debouncedSave(newData);
    };

    const columnDetail = actualList[actualList.findIndex(h => h.fieldName === props.fieldName)];
    const name = columnDetail?.fieldName.charAt(0).toUpperCase() + columnDetail?.fieldName.slice(1);
    //if (columnDetail?.fieldName === 'sodActivities') {
    //    props.setIsSodActivitySelected(selectedValues);
    //}

    const fillOptions = (assignValue) => {
        if (props.dataType === EditFiedDataType.MultiOptions || props.dataType === EditFiedDataType.Options ||
            props.dataType === EditFiedDataType.StaticMultiOptions || props.dataType === EditFiedDataType.StaticOptions) {
            if (props.dataType === EditFiedDataType.MultiOptions || props.dataType === EditFiedDataType.StaticMultiOptions)
                sV = [];
            switch (props.fieldName) {
                case 'criticality':
                    if (state.options.criticality?.length !== 0 &&
                        state.options.criticality.length !== options.length) {
                        state.options.criticality.forEach((opt) => {
                            options.push({
                                label: opt.value,
                                description: opt.description,
                                key: opt.value,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    if (assignValue === true && actualValue[columnDetail.fieldName] !== null && options.length != 0) {
                        let selectionArray;
                        if (actualValue[columnDetail.fieldName].length !== undefined) {
                            if (actualValue[columnDetail.fieldName][0]?.key !== undefined) {
                                selectionArray = [];
                                actualValue[columnDetail.fieldName].forEach((aCItem) => selectionArray.push(aCItem.key));
                            } else
                                selectionArray = actualValue[columnDetail.fieldName].split('/');
                        } else if (actualValue[columnDetail.fieldName].key !== undefined) {
                            selectionArray = [];
                            selectionArray.push(actualValue[columnDetail.fieldName].key);
                        }
                        if (selectionArray.length !== 0) {
                            let selectedOptionIndex;
                            selectionArray.forEach((aV) => {
                                if (options.findIndex(el => el.key == aV) !== -1)
                                    selectedOptionIndex = options.findIndex(el => el.key === aV);
                                sV = options[selectedOptionIndex];
                            });
                        }
                    }
                    break;
                case 'predictiveDetective':
                    if (state.options.predictiveDetective?.length !== 0 && state.options.specificMonitoring &&
                        state.options.predictiveDetective.length !== options.length) {
                        state.options.predictiveDetective.forEach((opt) => {
                            options.push({
                                label: opt.value,
                                description: opt.description,
                                key: opt.value,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    if (assignValue === true && actualValue[columnDetail.fieldName] !== null && options.length != 0) {
                        let selectionArray;
                        if (actualValue[columnDetail.fieldName].length !== undefined) {
                            if (actualValue[columnDetail.fieldName][0]?.key !== undefined) {
                                selectionArray = [];
                                actualValue[columnDetail.fieldName].forEach((aCItem) => selectionArray.push(aCItem.key));
                            } else
                                selectionArray = actualValue[columnDetail.fieldName].split('/');
                        } else if (actualValue[columnDetail.fieldName].key !== undefined) {
                            selectionArray = [];
                            selectionArray.push(actualValue[columnDetail.fieldName].key);
                        }
                        if (selectionArray.length !== 0) {
                            let selectedOptionIndex;
                            selectionArray.forEach((aV) => {
                                if (options.findIndex(el => el.key == aV) !== -1)
                                    selectedOptionIndex = options.findIndex(el => el.key === aV);
                                sV = options[selectedOptionIndex];
                            });
                        }
                    }
                    break;
                case 'specificMonitoring':
                    if (state.options.specificMonitoring?.length !== 0 && state.options.specificMonitoring &&
                        state.options.specificMonitoring.length !== options.length) {
                        state.options.specificMonitoring.forEach((opt) => {
                            options.push({
                                label: opt.value,
                                description: opt.description,
                                key: opt.value,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    handleSelectedOptionValue(assignValue, actualValue, columnDetail, options, sV, props.dataType);
                    break;
                case 'manualAutomaticSemiautomatic':
                    if (state.options.manualAutomaticSemiautomatic?.length !== 0 && state.options.manualAutomaticSemiautomatic &&
                        state.options.manualAutomaticSemiautomatic.length !== options.length) {
                        state.options.manualAutomaticSemiautomatic.forEach((opt) => {
                            options.push({
                                label: opt.value,
                                description: opt.description,
                                key: opt.value,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    handleSelectedOptionValue(assignValue, actualValue, columnDetail, options, sV, props.dataType);
                    break;
                case 'areas':
                    if (state.options.module?.length !== 0 && state.options.module &&
                        state.options.module.length !== options.length) {
                        state.options.module.forEach((opt) => {
                            options.push({
                                label: opt.key,
                                description: opt.description,
                                key: opt.key,
                                type: opt.type,
                                valueId: opt.value
                            });
                        });
                    }
                    handleSelectedOptionValue(assignValue, actualValue, columnDetail, options, sV, props.dataType);
                    break;
                case 'area':
                    if (state.options.module?.length !== 0 && state.options.module &&
                        state.options.module.length !== options.length) {
                        state.options.module.forEach((opt) => {
                            options.push({
                                label: opt.key,
                                description: opt.description,
                                key: opt.key,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    if (assignValue === true && options.length !== 0) {
                        if (options.findIndex(el => el.key == actualValue[columnDetail.fieldName]) !== -1)
                            sV = options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName])];
                        else if (options.findIndex(el => el.key == actualValue[columnDetail.fieldName]?.key) !== -1)
                            sV = options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName]?.key)];
                    }
                    break;
                case 'process':
                    if (state.options.process?.length !== 0 && state.options.process &&
                        state.options.process.length !== options.length) {
                        state.options.process.forEach((opt) => {
                            options.push({
                                label: opt.key,
                                description: opt.description,
                                key: opt.key,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    if (assignValue === true && options.length !== 0) {
                        if (options.findIndex(el => el.key == actualValue[columnDetail.fieldName]) !== -1)
                            sV = options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName])];
                        else if (options.findIndex(el => el.key == actualValue[columnDetail.fieldName]?.key) !== -1)
                            sV = options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName]?.key)];
                    }
                    break;
                case 'sodActivities':
                    if (state.options.sodActivity?.length !== 0 && state.options.sodActivity &&
                        state.options.sodActivity.length !== options.length) {
                        state.options.sodActivity.forEach((opt) => {
                            options.push({
                                label: opt.key,
                                description: opt.description,
                                key: opt.key,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    handleSelectedOptionValue(assignValue, actualValue, columnDetail, options, sV, props.dataType);
                    break;
                case 'risks':
                    if (props.item && props.item.sodActivities  !== undefined) {
                        const filteredRisksOptions = [];

                        if (actualValue?.risks?.length > 0 && actualValue?.sodActivities?.length == 0)
                            actualValue.risks.forEach(r => filteredRisksOptions.push(r));
                        else
                            state.soDRisks.forEach(r => {
                                props.item?.sodActivities.forEach(s => {
                                    if (s.value == r.firstSodActivity?.toString() || s.value == r.secondSodActivity?.toString() ||
                                        s == r.firstSodActivity?.toString() || s == r.secondSodActivity?.toString()) {
                                        filteredRisksOptions.push(r.id);
                                        return;
                                    }
                                });
                            });
                        if (state.complexOptions?.risk?.length !== 0 && state.complexOptions.risk &&
                            state.complexOptions.risk.length !== options.length) {
                            if (filteredRisksOptions.length !== 0)
                                state.complexOptions.risk.forEach((opt) => {
                                    if (filteredRisksOptions.findIndex(f => f == opt.key) !== -1 &&
                                        options.findIndex(o => o.key == opt.key) === -1) {
                                        options.push({
                                            label: opt.key,
                                            description: opt.description,
                                            key: opt.key,
                                            type: null,
                                            value: opt.value
                                        });
                                    }
                                });
                        }
                        const verificaElementi = [];

                            actualValue?.risks?.forEach(a => {
                                if (options.findIndex(o => o.key == a || o.key == a.key) !== -1)
                                    verificaElementi.push(a);
                            });

                        if (actualValue && actualValue.risks &&
                            actualValue.risks.length !== verificaElementi.length)
                            updateOptionsValue([], verificaElementi);

                        handleSelectedOptionValue(assignValue, actualValue, columnDetail, options, sV, props.dataType);
                    }
                    break;
                case 'firstSodActivity':
                    if (state.options.sodActivity?.length !== 0 && state.options.sodActivity &&
                        state.options.sodActivity.length !== options.length) {
                        state.options.sodActivity.forEach((opt) => {
                            options.push({
                                label: opt.key,
                                description: opt.description,
                                key: opt.key,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    if (assignValue === true && options.length !== 0) {
                        if (options.findIndex(el => el.key == actualValue[columnDetail.fieldName]) !== -1)
                            sV = options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName])];
                        else if (options.findIndex(el => el.key == actualValue[columnDetail.fieldName]?.key) !== -1)
                            sV = options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName]?.key)];
                    }
                    break;
                case 'secondSodActivity':
                    if (state.options.sodActivity?.length !== 0 && state.options.sodActivity &&
                        state.options.sodActivity.length !== options.length) {
                        state.options.sodActivity.forEach((opt) => {
                            options.push({
                                label: opt.key,
                                description: opt.description,
                                key: opt.key,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    if (assignValue === true && options.length !== 0) {
                        if (options.findIndex(el => el.key == actualValue[columnDetail.fieldName]) !== -1)
                            sV = options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName])];
                        else if (options.findIndex(el => el.key == actualValue[columnDetail.fieldName]?.key) !== -1)
                            sV = options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName]?.key)];
                    }
                    break;
                case "code"://CompensatoryControlRisks
                    if (state.options.compensatoryControl?.length !== 0 && state.options.compensatoryControl &&
                        state.options.compensatoryControl.length !== options.length) {
                        state.options.compensatoryControl.forEach((opt) => {
                            options.push({
                                label: opt.key,
                                description: opt.description,
                                key: opt.key,
                                type: opt.type,
                                value: opt.value
                            });
                        });
                    }
                    if (assignValue === true &&
                        options.length !== 0 && options.findIndex(el => el.key == actualValue[columnDetail.fieldName]) !== -1) {
                        sV.push(options[options.findIndex(el => el.key == actualValue[columnDetail.fieldName])]);
                    }
                    break;
                default:
                    console.log(props.fieldName);
                    throw new Error("Case not supported");
            }
            if (assignValue === true &&
                selectedValues?.length !== sV?.length) {
                setSelectedValues(sV);
            } else if (assignValue === true &&
                (props.dataType === EditFiedDataType.Options || props.dataType === EditFiedDataType.StaticOptions) && sV?.key !== selectedValues?.key) {
                setSelectedValues(sV);
            }

            filteredOptions = options;
        }
    };

    fillOptions(isNewItem() ? false : true);

    const updateValue = (value) => {
        const lowerName = name.charAt(0).toLowerCase() + name.slice(1);
        props.setNewValue(lowerName, value);
        setActualValue({ [lowerName]: value });
    };

    const onOpenAutocomplete = (e, options) => {
        if (e.textContent === '') {
            if (options === undefined)
                options = [];
            if (state.options.length !== 0 && state.options.module) {
                fillOptions(false);
            }
        }
    }
    const checkBooleanValue = (assignValue) => {
        if (props.dataType === EditFiedDataType.Boolean) {
            if (actualValue[columnDetail.fieldName] != 'true')
                setChecked(false);
        }
    };

    const handleChange = (event) => {
        const lowerName = name.charAt(0).toLowerCase() + name.slice(1);
        setChecked(event.target.checked);
        props.setNewValue(lowerName, event.target.checked);
        setActualValue({ [lowerName]: event.target.checked });
    };

    const debouncedOption = useCallback(
        debounce((text) => getOption(text), 300),
        []
    );

    const getOption = (text) => {
        filteredOptions = options.filter(x => x.value.includes(text) || x.description.includes(text));
    }

    const onInputChange = (text, o) => {
        if (text === "" || text === undefined)
            filteredOptions = options;
        if (text !== undefined && text !== "")
            filteredOptions = options.filter(x => {
                x.value?.includes(text) || x.description?.includes(text)
            });
        //debouncedOption(text);
    };

    const getLabel = (option) => {
        var lbl = '';
        if (option) {
            if (props.fieldName === 'risks')
                lbl = option.value;
            else if (props.fieldName === 'sodActivities' || props.fieldName === 'firstSodActivity' || props.fieldName === 'secondSodActivity')
                lbl = option.value ? (option.value + ' - ' + option.description) : '';
            else if (option && option.description !== undefined)
                lbl = option.description;
            else if (option && option.label !== undefined)
                lbl = option.label;
        }
        return lbl;
    }

    return (
        <>
            {
                columnDetail?.filter ?
                    <Grid item xs={6}>
                        {
                            (props.dataType === EditFiedDataType.MultiOptions || props.dataType === EditFiedDataType.Options ||
                                props.dataType === EditFiedDataType.StaticMultiOptions || props.dataType === EditFiedDataType.StaticOptions) ?
                                <Grid sx={{ width: '80%', margin: 'auto' }} >
                                    <Autocomplete
                                        style={{ marginTop: 8, lineHeight: '1rem' }}
                                        fullWidth
                                        multiple={props.dataType === EditFiedDataType.MultiOptions || props.dataType === EditFiedDataType.StaticMultiOptions ? true : false}
                                        id="size-small-outlined-multi"
                                        size="small"
                                        limitTags={2}
                                        options={filteredOptions}
                                        getOptionLabel={(option) => {
                                            return getLabel(option);
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option?.key}> {getLabel(option)} </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={columnDetail.displayName} />
                                        )}
                                        defaultValue={['']}
                                        value={selectedValues}
                                        onInputChange={(e, options) =>
                                            onInputChange(e?.target?.value, options)
                                        }
                                        onChange={(e, options) =>
                                            updateOptionsValue(e.target, options)
                                        }
                                        onOpen={(e, options) =>
                                            onOpenAutocomplete(e.target, options)
                                        }
                                        required={props.required}
                                    />
                                </Grid>
                                :
                                props.dataType === EditFiedDataType.TextField ?
                                    <Grid sx={{ width: '80%', margin: 'auto' }} >
                                        <TextField
                                            style={{ marginTop: 8 }}
                                            size='small'
                                            margin='dense'
                                            fullWidth
                                            label={columnDetail.displayName}
                                            value={actualValue !== undefined ? actualValue?.[columnDetail.fieldName] : ''}
                                            type="string"
                                            onChange={(e) => updateValue(e.target.value)}
                                            InputProps={{ endAdornment: null }}
                                            required={props.required}
                                        />
                                    </Grid>
                                    :
                                    <FormGroup >
                                        <FormControlLabel sx={{ width: '80%', margin: 'auto', justifyContent: 'start' }}
                                            control={
                                                <Switch
                                                    checked={checked}
                                                    onChange={handleChange}
                                                />
                                            }
                                            labelPlacement='start'
                                            label={columnDetail.displayName}
                                        />
                                    </FormGroup>
                        }
                    </Grid>
                    :
                    undefined
            }
        </>
    );
}

function handleSelectedOptionValue(assignValue, actualValue, columnDetail, options, sV, dataType) {
    if (assignValue === true &&
        actualValue[columnDetail.fieldName] !== null && actualValue[columnDetail.fieldName].length !== undefined && options.length != 0) {
        let selectedOptionIndex;
        if (dataType === EditFiedDataType.StaticMultiOptions) {
            let selectionArray = [];
            if (actualValue[columnDetail.fieldName] !== '' && actualValue[columnDetail.fieldName] !== undefined && actualValue[columnDetail.fieldName].length !== 0) {
                if (actualValue[columnDetail.fieldName][0]?.key !== undefined) {
                    selectionArray = [];
                    actualValue[columnDetail.fieldName].forEach((aCItem) => selectionArray.push(aCItem.key));
                } else
                    selectionArray = actualValue[columnDetail.fieldName].split('/');
            }
            if (selectionArray.length !== 0)
                selectionArray.forEach((aV) => {
                    if (options.findIndex(el => el.key == aV) !== -1) {
                        selectedOptionIndex = options.findIndex(el => el.key === aV);
                    }
                    if (selectedOptionIndex !== -1)
                        sV.push(options[selectedOptionIndex]);
                });
        } else {
            actualValue[columnDetail.fieldName].forEach((aV) => {
                if (Number.isInteger(aV)) {
                    selectedOptionIndex = options.findIndex(el => Number(el.key) === aV);
                }
                else if (options.findIndex(el => el.key == aV.valueId) !== -1) {
                    selectedOptionIndex = options.findIndex(el => el.key === aV.valueId);
                }
                else if (options.findIndex(el => el.key == aV.key) !== -1) {
                    selectedOptionIndex = options.findIndex(el => el.key === aV.key);
                }
                if (selectedOptionIndex !== -1)
                    sV.push(options[selectedOptionIndex]);
            });
        }
    }
}

function handleSelectedOptionSingleValue(assignValue, actualValue, columnDetail, options, sV) {
    if (assignValue === true &&
        actualValue[columnDetail.fieldName] !== null && actualValue[columnDetail.fieldName].length !== undefined && options.length !== 0) {
        let selectedOptionIndex = 0;
        if (Number.isInteger(actualValue)) {
            selectedOptionIndex = options.findIndex(el => Number(el.key) === actualValue);
        }
        else if (actualValue) {
            if (actualValue[columnDetail.fieldName] !== '' && actualValue[columnDetail.fieldName] !== undefined && actualValue[columnDetail.fieldName].length !== 0&& 
                options.findIndex(el => el.key == actualValue[columnDetail.fieldName]) !== -1) {
                selectedOptionIndex = options.findIndex(el => el.key == actualValue[columnDetail.fieldName]);
            } else if (options.findIndex(el => el.key == actualValue.valueId) !== -1) {
                selectedOptionIndex = options.findIndex(el => el.key === actualValue.valueId);
            }
            else if (options.findIndex(el => el.key == actualValue.key) !== -1) {
                selectedOptionIndex = options.findIndex(el => el.key === actualValue.key);
            }
        }

        sV = options[selectedOptionIndex];
        return sV;
    } else 
        return [];
}
