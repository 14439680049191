export const truncateString = (str, num) => {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}

export function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}

export const isURL = (str) => /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str)

export const deepClone = (obj) => obj && JSON.parse(JSON.stringify(obj));