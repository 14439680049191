import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from "react";
import { useParams } from "react-router";
import { DataContext } from '../../contexts/DataContext';
import { UserContext } from '../../contexts/UserContext';
import { ApplicationRoles, deepClone, TransactionEntityName } from '../../utils';
import { DependencyList } from '../../ui-components/DependencyList';
import { KeyValueList } from '../../ui-components/KeyValueList';
import { ComplexEntityNames } from '../../helpers';
import DetailToolbarEdit from '../../ui-components/DetailToolbarEdit';

export const CompensatoryControlsDetailPage = (props) => {
    const {
        state,
        loadCompensatoryControls,
        // isLoadingSoDObjectDependencies,
        // cleanSoDObjectDependencies,
        // loadSoDObjectDependencies,
        resolveOptions,
        loadOptions,
        isLoadingOptions,
        loadComplexOptions,
        loadSoDRisks
    } = useContext(DataContext);
    const { userState, setActualSection, isUserInAnyRole } = useContext(UserContext);

    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);

    if (!isLoadingOptions && (state.options.risk?.length === 0 || state.options.sodActivity?.length === 0))
        loadOptions('CompensatoryControlsGridOptions');

    React.useEffect(() => {
        let active = true;
        (
            async () => {
                setLoading(true);
                await loadCompensatoryControls(state.filterCompensatoryControl);
                if (!active) { return; }
                setLoading(false);
            }
        )();
        return () => { active = false; };
    }, []);

    React.useEffect(() => {
        loadComplexOptions(
            ComplexEntityNames.SodActivity,
            ComplexEntityNames.Risk
        );
        if (state.soDRisks.length === 0)
            loadSoDRisks();
    }, []);

    const cc = state.compensatoryControls?.find(el => el.id === Number(id));

    if (userState.actualSection !== 'CompensatoryControlsDetailPage')
        setActualSection('CompensatoryControlsDetailPage');

    const memoizedCC = React.useMemo(() => {
        if (!cc) return;
        const cc1 = deepClone(cc);
        cc1.risks = resolveOptions(ComplexEntityNames.Risk, cc.risks);
        cc1.sodActivities = resolveOptions(ComplexEntityNames.SodActivity, cc.sodActivities);
        return cc1;
    }, [cc]);

    const dependencies = state.sodObjectDependencies;
    return (
        memoizedCC ?
            <>
                <Typography variant="h5" gutterBottom component="div" align="center" paddingTop={5}>
                    Compensatory Controls Details
                </Typography>

                <Box sx={{ mx: { xs: 5, md: 15, lg: 25, xl: 40 }, my: 3, marginBottom: 0 }}>
                    <Grid container spacing={4} sx={{ marginBottom: '60px' }}>
                        {
                            isUserInAnyRole([ApplicationRoles.Admin, ApplicationRoles.WWIC]) &&
                            <Grid item xs={12}>
                                <DetailToolbarEdit id={id} {...props} />
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <KeyValueList
                                title="Compensatory Control details"
                                data={memoizedCC}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', padding: 10 }}
                                component={Paper}
                                labels={[
                                    { property: "code", label: "Control Code" },
                                    { property: "description", label: "Compensatory Control Description" },
                                ]}
                            />
                            <KeyValueList
                                data={memoizedCC}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', padding: 10, marginTop: 30 }}
                                component={Paper}
                                labels={[
                                    { property: "name", label: "Compensatory Control Name" },
                                    { property: "category", label: "Compensatory Control Category" },
                                    { property: "ericaCode", label: "Erica Code/ICF Code" },
                                    { property: "linkedToHighPriorityRisk", label: "SoD Activity Description" },
                                    { property: "manualAutomaticSemiautomatic", label: "Manual Automatic Semiautomatic" },
                                    { property: "minPeriodicity", label: "Min Periodicity" },
                                    { property: "details", label: "Details" },
                                    { property: "predictiveDetective", label: "Preventive Detective" },
                                    { property: "specificMonitoring", label: "Specific Monitoring" },
                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <KeyValueList
                                title="SoD Objects"
                                data={memoizedCC}
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)', padding: 10 }}
                                component={Paper}
                                labels={[
                                    { property: "risks", label: "SoD Risks" },
                                    { property: "sodActivities", label: "SoD Activities" },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </>
            :
            <></>
    );
}