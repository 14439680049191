import React from "react";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Link, Tooltip, Typography, Grid, Divider, Stack, Chip } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import { isURL } from "../utils";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        fontSize: 15.5
    },
    key: {
        fontWeight: 500,
        fontSize: 7
    },
    value: {
        display: "flex",
        alignItems: "flex-end"
    },
    dividerWrapper: {
        padding: theme.spacing(2, 0)
    },
    fontTitle: {
        fontSize: 13
    },
    chip: {
        display: "block",
        alignItems: "flex-end"
    }
}));

/**
 * Renders an object like a list of key value. To override the property names 
 * pass in the prop labels as an array of { property:"", label:"" }. 
 * When labels prop is supplied it will dictate the order of the properties in the list.
 */
export const KeyValueList = ({
    className,
    data,
    style,
    labels,
    component,
    title,
    noWrap
}) => {
    const classes = useStyles();

    const deleteFromObject = (keyPart, obj) => {
        for (var k in obj) {          // Loop through the object
            if (~k.indexOf(keyPart)) { // If the current key contains the string we're looking for
                delete obj[k];       // Delete obj[key];
            }
        }
    }

    const dataShallowClone = { ...data };

    if (!labels || (labels?.length === 0 ?? true)) throw new Error('Labels array required');

    //clean data
    const toKeep = labels.map(l => l.property);
    const toRemove = Object.keys(dataShallowClone).filter(value => !toKeep.includes(value));
    toRemove.forEach(p => {
        deleteFromObject(p, dataShallowClone);
    });

    const itemsArray = Object.entries(dataShallowClone);
    const getItems = () => {
        if (labels) {
            const sortingArray = labels.map(x => x.property);
            return itemsArray.sort((a, b) => {
                return sortingArray.indexOf(a[0]) - sortingArray.indexOf(b[0]);
            })
        }
        return itemsArray;
    }

    const renderValue = (value) => {
        if (Array.isArray(value)) {
            return <Stack direction="row" justifyContent="flex-start"  sx={{ flexFlow: "wrap"}}  >
                {value.map(x => <Chip label={x} sx={{ flexFlow: "wrap", marginBottom: '3px', marginRight: '3px' }} />)}
            </Stack>
        }
        if (isURL(String(value)))
            return (<Link href={value} target="_blank" rel="noreferrer">
                {value}
            </Link>)
        return String(value);
    }

    const items = getItems();
    return (
        <>
            {title &&
                <Typography variant="h6">{title}</Typography>
            }
            <Grid
                container
                className={clsx(className, classes.root)}
                style={style}
                component={component}>
                {items.map(([key, value], index) => {
                    const currentItem = labels?.find(x => x.property === key);
                    return (
                        <Grid item container key={key} data-testid="keyvaluelist">
                            <Grid item xs={12} sm={5} className={classes.key}>
                                {currentItem?.tooltipText ?
                                    <Tooltip title={currentItem?.tooltipText}>
                                        <Typography sx={{ fontWeight: 'bold', wordWrap: "break-word" }} className={classes.fontTitle}>{currentItem?.label ?? key}</Typography>
                                    </Tooltip> :
                                    <Typography sx={{ fontWeight: 'bold', wordWrap: "break-word"}} className={classes.fontTitle}>{currentItem?.label ?? key}</Typography>
                                }
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.chip  }>
                                <Typography noWrap={noWrap}  variant="caption">{renderValue(value)}</Typography>
                            </Grid>
                            <Grid item xs={2} sm={1} className={classes.value}>
                                <CopyToClipboard text={value}>
                                    <Tooltip title="Copy">
                                        <IconButton size="small">
                                            <FileCopyOutlinedIcon
                                                style={{
                                                    width: 18,
                                                    height: 18
                                                }} />
                                        </IconButton>
                                    </Tooltip>
                                </CopyToClipboard>
                            </Grid>
                            {index + 1 !== items.length &&
                                <Grid xs={12} item className={classes.dividerWrapper}>
                                    <Divider />
                                </Grid>}
                        </Grid>
                    );
                })}
            </Grid></>
    );
};
