/* eslint-disable default-case */
import ArticleIcon from '@mui/icons-material/Article';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Box, IconButton
} from '@mui/material';
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { DataContext } from '../../contexts/DataContext';
import { UserContext } from '../../contexts/UserContext';
import { downloadExcelFromDatagrid, NavRoutes, copyToClipboard, doSimplifiedFuzzySeach, ApplicationRoles } from "../../utils";
import DatagridToolbar from '../DatagridToolbar';
import DeleteWithConfirm from '../DeleteWithConfirm';
import { StyledDataGrid, StyledTooltip } from '../StyledTableComponents';
import Tooltip from '@mui/material/Tooltip';
import { AuthPlatformTableNames, ComplexEntityNames } from '../../helpers';

function getRender(field, row, optionResolver) {
    let text;
    let fragment = '';
    let txtToCopy;
    switch (field) {
        case "code":
            text = row.code;
            break;
        case "firstSodActivity":
            text = optionResolver(ComplexEntityNames.SodActivity, row.firstSodActivity);
            break;
        case "secondSodActivity":
            text = optionResolver(ComplexEntityNames.SodActivity, row.secondSodActivity);
            break;
        case "areas":
            text = [];
            txtToCopy = [];
            row.areas.forEach(x => {
                const optionValue = optionResolver(AuthPlatformTableNames.Area, x)
                if (text.length !== 0) {
                    text.push(<br />);
                }
                text.push(optionValue);
                txtToCopy.push(optionValue);
            });
            break;
        case "description":
            text = row.description;
            break;
        case "impact":
            text = row.impact;
            break;
        case "impactApplication":
            text = row.impactApplication;
            break;
        case "criticality":
            text = row.criticality;
            break;
        case "grcRiskId":
            text = row.grcRiskId;
            break;
        case "italianDescription":
            text = row.italianDescription;
            break;
    }

    handleFragment();

    return {
        text,
        cell: (
            <StyledTooltip title={
                <>
                    {text}
                    <IconButton
                        onClick={() => copyToClipboard(txtToCopy ? txtToCopy : text)}
                        variant='contained'
                        size='small'>
                        <ContentCopyIcon fontSize='small' />
                    </IconButton >
                </>
            }
            // arrow style={{ whiteSpace: 'pre', }}
            >
                <span style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                    {
                        fragment ? fragment : text
                    }

                </span>
            </StyledTooltip>
        )
    };

    function handleFragment() {
        if (text) {
            fragment =
                <div style={{ whiteSpace: 'normal', display: "-webkit-box", '-webkit-line-clamp': '3', '-webkit-box-orient': 'vertical', overflow: "hidden" }}>
                    {text}
                </div>;
        }
    }
}

export default function SoDRisksDataGrid(props) {
    const {
        state,
        loadSoDRisks,
        isLoadingSoDRisks,
        isDeleting,
        deleteRisk,
        setSelectedTab,
        pageState,
        resolveOption,
        loadComplexOptions,
        isLoadingComplexOptions,
        // loadOptions
    } = useContext(DataContext);

    const { userState, setActualSection, isUserInRole } = useContext(UserContext);
    const [query, setQuery] = React.useState();

    const memoizedColumns = React.useMemo(() => {
        let columns = [];

        columns.push({
            headerName: 'TCode Card',
            field: "click",
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        {isUserInRole(ApplicationRoles.Admin) &&
                            <StyledTooltip title="Delete item" aria-label="delete-item" >
                                <span>
                                    <DeleteWithConfirm
                                        loading={isDeleting}
                                        handleDelete={() => deleteRisk(params.row.id)}
                                        onDeleted={() => loadSoDRisks()} />
                                </span>
                            </StyledTooltip>}
                        <StyledTooltip title="View Detail" aria-label="view-detail" >
                            <IconButton style={{ marginLeft: 15 }}
                                onClick={() => {
                                    if (userState.actualSection !== 'RisksDetailPage')
                                        setActualSection('RisksDetailPage');
                                    if (pageState.selectedTab !== 'SoDRisks')
                                        setSelectedTab('SoDRisks');
                                    history.push(NavRoutes.RisksDetailPage(params.row.id))
                                }}
                                variant='contained'>
                                <ArticleIcon />
                            </IconButton >
                        </StyledTooltip>
                    </>);
            }
        });

        state.actualSoDRisksHeadersList.sort((a, b) => a.columnsOrder - b.columnsOrder).map(head => (
            columns.push({
                id: head.key,
                headerName: head.headerName,
                field: head.fieldName,
                description: head.headerName,
                headerAlign: head.headerAlign,
                flex: head.flex,
                minWidth: head.minWidth,
                hideable: true,
                hide: head.hidden,
                groupable: false,
                Tooltip,
                renderCell: (params) => (
                    getRender(params.colDef.field, params.row, resolveOption).cell
                ),
            })
        ))
        return (columns);
    }, [pageState.selectedTab, setActualSection, setSelectedTab, state.actualSoDRisksHeadersList, userState.actualSection]);

    const memoizedRows = React.useMemo(() => {
        let rows = [];
        if (state.soDRisks !== undefined) {
            state.soDRisks.forEach(t => {
                const o = {};
                Object.defineProperty(o, 'internalId', {
                    value: t['id'],
                    writable: false,
                    enumerable: true
                });

                state.actualSoDRisksHeadersList.forEach((head) => {
                    Object.defineProperty(o, head.fieldName, {
                        value: t[head.fieldName],
                        writable: false,
                        enumerable: true
                    });
                })
                rows.push(o);
            });
        }

        return query ? doSimplifiedFuzzySeach(query, rows, {
            threshold: -5000, // don't return bad results
            keys: [
                'description',
                'code',
                'criticality',
                'id',
                'impact',
                'italianDescription',
                'impactApplication'
            ],
        }) : rows;
    }, [state.actualSoDRisksHeadersList, state.soDRisks, query]);

    React.useEffect(() => {
        (
            async () => {
                await loadSoDRisks(state.filterSod);
            }
        )();
    }, []);

    React.useEffect(() => {
        loadComplexOptions(
            ComplexEntityNames.SodActivity
        );
        // loadOptions();
    }, []);

    const history = useHistory();

    return (
        <>
            <DatagridToolbar
                dataGrid='Risks'
                handleGlobalSearch={(query) => setQuery(query)}
                onDownload={() =>
                    downloadExcelFromDatagrid(
                        memoizedRows,
                        memoizedColumns.filter(x => x.field !== 'click'),
                        'SoD Risks',
                        (col, row) => getRender(col, row, resolveOption))}
                loading={isLoadingSoDRisks}
                {...props} />
            <Box
                sx={{
                    height: 600,
                    '& .super-app-them  e--header': {
                        backgroundColor: '#787878',
                    },
                }}
            >
                <StyledDataGrid style={{ height: 600, width: '100%' }}
                    columns={memoizedColumns}
                    rows={memoizedRows}
                    rowsPerPageOptions={[100, 200, 300]}
                    disableExtendRowFullWidth={false}
                    getRowId={(row) => row.internalId}
                    density={'compact'}
                    headerHeight={50}
                    rowHeight={90}
                    disableColumnMenu={true}
                    autoPageSize={false}
                    hideFooterPagination={false}
                    hideFooter={false}
                    rowCount={memoizedRows.length}
                    loading={isLoadingSoDRisks || isLoadingComplexOptions}
                    disableSelectionOnClick
                />
            </Box>
        </>
    );
}
