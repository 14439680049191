import {
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import React from "react";
import DetailToolbar from './DetailToolbar';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function DetailToolbarAdd(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);

    }; 

    return (
        <div>
            <Grid container alignContent="flex-start" >
                <Grid item xl={0.5} md={0.5} xs={0.5} >
                    <Tooltip title="Create new item" aria-label="new">
                        <IconButton onClick={handleClickOpen} variant='contained'>
                            <AddCircleIcon />
                        </IconButton >
                    </Tooltip>
                    <DetailToolbar newItem={true} open={open} handleClose={() => setOpen(false)} {...props} />
                </Grid>
            </Grid>
        </div>
    );
}