import React from "react";
import { Typography, Toolbar, AppBar, Grid } from "@mui/material";

const Footer = () => {

    return (<AppBar
        position="fixed"
        sx={{ top: 'auto', bottom: 0, height: 40, minheight: 'none' }}>
        <Toolbar
            sx={{ minheight: 'none',
            height: 50,
                    }}
        >
            <Grid
                container
                sx={{ height: 50 }}
                columns={2}
                direction={'column'}>
                <Grid item>
                    <Typography
                        sx={{ height: 50, fontWeight: 'bold'  }}
                        variant="subtitle1"
                        color="secondary">
                        SoD Matrix Tool
                    </Typography>
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>);
};

export default Footer;