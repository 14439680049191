import { Box, Typography } from "@mui/material";
import { default as React } from "react";
import SoDMatrixDataGrid from "../ui-components/dataGrid/SoDMatrixDataGrid";
import SodMatrixPageFilters from "../ui-components/SodMatrixPageFilters";

export const SoDMatrixPage = (props) => {
    return (
        <>
            <Typography variant="h5" gutterBottom component="div" align="center" paddingTop={2}>
                Compensatory Control to mitigate SoD Risk
            </Typography>

            <Box sx={{ mx: 20, my: 2 }}>
                <SodMatrixPageFilters />
            </Box>

            <Box sx={{ mx: 7, my: 2 }}>
                <SoDMatrixDataGrid
                    {...props}
                />
            </Box>
        </>
    );
}