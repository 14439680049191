import React, { useContext, useState } from 'react';
import { useAggregatedState, ComplexEntityNames, AllAuthPlatformTableNames, staticOptions } from '../helpers';
import { ApiClient, ApiRoutes, TransactionEntityName } from "../utils";
import { InsightContext } from "./";
import { useSnackbar } from 'notistack';

export const DataContext = React.createContext(null);

const { Provider } = DataContext;
export const DataProvider = (props) => {

    const { trackError } = useContext(InsightContext);
    const { enqueueSnackbar } = useSnackbar();
    const defaltErrorMessageLoadData = "Cannot load data. Something went wrong with the server";
    const defaltErrorMessageWriteData = "Cannot modify or add item. Something went wrong with the server";
    const defaltErrorMessageDeleteData = "Cannot delete item. Something went wrong with the server";
    const defaultSuccessMessageAdd = "New item created successfully";
    const defaultSuccessMessageUpdate = "Item updated successfully";
    const defaultSuccessMessageDelete = "Item deleted successfully";

    function notifyError(error, message) {
        console.debug(error);

        if (error?.response.status === 400)
            enqueueSnackbar("Bad request, please check the data you entered was correct");
        else
            enqueueSnackbar(message);
    }

    const [state, setState] = useAggregatedState({
        transactions: [],

        isSearchingMode: false,
        totalItems: 0,
        totalSodActivities: 0,
        totalSoDRisks: 0,
        totalCompensatoryControls: 0,
        requests: [],
        sodActivities: [],
        soDRisks: [],
        compensatoryControls: [],
        sortModel:
            [{
                field: '',
                sort: ''
            }],
        filter: {
            category: 2,
            searchText: "",
            pageIndex: 0,
            pageSize: 100,
            propertyFilters: [
            ],
        },
        filterSod: {
            category: 2,
            searchText: "",
            pageIndex: 0,
            pageSize: 100,
            propertyFilters: [
            ],
        },
        filterRisks: {
            category: 2,
            searchText: "",
            pageIndex: 0,
            pageSize: 100,
            propertyFilters: [
            ],
        },
        filterCompensatoryControl: {
            category: 2,
            searchText: "",
            pageIndex: 0,
            pageSize: 100,
            propertyFilters: [
            ],
        },
        selectionModel: [],
        hiddenRows: false,
        options: {
            process: [],
            module: [],
            sodActivity: [],
            compensatoryControl: [],
            risk: [],
            criticality: [
                { description: staticOptions.High, type: 0, key: 0, value: staticOptions.High },
                { description: staticOptions.Medium, type: 0, key: 1, value: staticOptions.Medium },
                { description: staticOptions.Low, type: 0, key: 2, value: staticOptions.Low }
            ],
            predictiveDetective: [
                { description: staticOptions.Preventive, type: 0, key: 0, value: staticOptions.Preventive },
                { description: staticOptions.Detective, type: 0, key: 1, value: staticOptions.Detective }
            ],
            specificMonitoring: [
                { description: staticOptions.Specific, type: 0, key: 0, value: staticOptions.Specific },
                { description: staticOptions.Monitoring, type: 0, key: 1, value: staticOptions.Monitoring },
                { description: staticOptions.Organizational, type: 0, key: 2, value: staticOptions.Organizational }
            ],
            manualAutomaticSemiautomatic: [
                { description: staticOptions.Manual, type: 0, key: 0, value: staticOptions.Manual },
                { description: staticOptions.Automatic, type: 0, key: 1, value: staticOptions.Automatic },
                { description: staticOptions.Semiautomatic, type: 0, key: 2, value: staticOptions.Semiautomatic }
            ],
        },
        complexOptions: {
            compensatoryControl: [],
            risk: [],
            sodActivity: []
        },
        actualHeadersList: [
            { key: 0, columnsOrder: -1, filter: 'blocked', hidden: 'blocked', minWidth: 50, flex: 0.8, fieldName: "id", headerName: "Id", displayName: "Id" },
            { key: 1, columnsOrder: 0, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "code", headerName: "Control Code", displayName: "Control Code" },
            { key: 2, columnsOrder: 1, filter: false, hidden: false, minWidth: 50, flex: 1, fieldName: "name", headerName: "Compensatory Control Name", displayName: "Compensatory Control Name" },
            { key: 3, columnsOrder: 2, filter: false, hidden: false, minWidth: 50, flex: 1, fieldName: "description", headerName: "Compensatory Control Description", displayName: "Compensatory Control Description" },
            { key: 4, columnsOrder: 3, filter: true, hidden: false, minWidth: 50, flex: 1, fieldName: "predictiveDetective", headerName: "Control Type (Preventive/Detective)", displayName: "Control Type (Preventive/Detective)" },
            { key: 5, columnsOrder: 4, filter: false, hidden: false, minWidth: 50, flex: 1, fieldName: "specificMonitoring", headerName: "Control Type (Specific/Monitoring/Organizational)", displayName: "Control Type (Specific/Monitoring/Organizational)" },
            { key: 6, columnsOrder: 5, filter: false, hidden: false, minWidth: 50, flex: 1, fieldName: "category", headerName: "Compensatory Control Category", displayName: "Compensatory Control Category" },
            { key: 7, columnsOrder: 6, filter: 'blocked', hidden: false, minWidth: 50, flex: 1, fieldName: "manualAutomaticSemiautomatic", headerName: "Control Type (Manual/Automatic/Semiautomatic)", displayName: "Control Type (Manual/Automatic/Semiautomatic)" },
            { key: 8, columnsOrder: 7, filter: 'blocked', hidden: false, minWidth: 50, flex: 1, fieldName: "minPeriodicity", headerName: "Minimum Frequency required", displayName: "Minimum Frequency required" },
            { key: 9, columnsOrder: 8, filter: true, hidden: false, minWidth: 50, flex: 1, fieldName: "sodActivityCode", headerName: "SoD Activity", displayName: "SoD Activity" },
            //{ key: 9, columnsOrder: 8, filter: true, hidden: false, minWidth: 50, flex: 1, fieldName: "sodActivityCode", headerName: "Activity code", displayName: "Activity code" },
            //{ key: 10, columnsOrder: 9, filter: false, hidden: false, minWidth: 50, flex: 1, fieldName: "sodActivityDescription", headerName: "Activity description", displayName: "Activity description" },
            { key: 10, columnsOrder: 9, filter: true, hidden: false, minWidth: 50, flex: 1, fieldName: "riskCode", headerName: "SoD Risks", displayName: "SoD Risks" },
            { key: 11, columnsOrder: 10, filter: 'blocked', hidden: false, minWidth: 50, flex: 1, fieldName: "details", headerName: "Details", displayName: "Details" },
            { key: 12, columnsOrder: 11, filter: 'blocked', hidden: false, minWidth: 50, flex: 1, fieldName: "ericaCode", headerName: "Erica code/ICF Code", displayName: "Erica Code/ICF Code" },
        ],

        actualSodActivitiesHeadersList: [
            { key: 0, columnsOrder: -1, filter: 'blocked', hidden: 'blocked', minWidth: 50, flex: 0.8, fieldName: "id", headerName: "Id", displayName: "Id" },
            { key: 1, columnsOrder: 0, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "code", headerName: "SoD Activity", displayName: "SoD Activity" },
            //{ key: 1, columnsOrder: 0, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "code", headerName: "SoD Activity Code", displayName: "SoD Activity Code" },
            { key: 2,  columnsOrder: 1, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "area", headerName: "Area", displayName: "Area" },
            { key: 3,  columnsOrder: 2, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "linkedToHighPriorityRisk", headerName: "Linked to High Priority Risk", displayName: "Linked to High Priority Risk" },
            { key: 4,  columnsOrder: 3, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "sys", headerName: "System", displayName: "System" },
            { key: 5,  columnsOrder: 4, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "italianDescription", headerName: "Italian Description", displayName: "Italian Description" },
            { key: 6, columnsOrder: 5, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "grcRiskId", headerName: "Grc Risk Id", displayName: "Grc Risk Id" },
            { key: 7, columnsOrder: 6, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "note", headerName: "Note", displayName: "Note" },
            { key: 2, columnsOrder: -2, filter: true, hidden: 'blocked', minWidth: 50, flex: 0.8, fieldName: "description", headerName: "SoD Activity Description", displayName: "SoD Activity Description" },
        ],  //{ key: 4,  columnsOrder: 3, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "isSensitive", headerName: "Sensitive", displayName: "Sensitive" },
            //{ key: 7,  columnsOrder: 6, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "process", headerName: "Process", displayName: "Process" },
            //{ key: 8,  columnsOrder: 7, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "transactionIviewSap", headerName: "Transaction Iview Sap", displayName: "Transaction Iview Sap" },
            //{ key: 9,  columnsOrder: 8, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "lvmhSodActivityReference", headerName: "LVMH SoD Activity Ref.", displayName: "LVMH SoD Activity Ref." },

        actualSoDRisksHeadersList: [
            { key: 0, columnsOrder: -1, filter: 'blocked', hidden: 'blocked', minWidth: 50, flex: 0.8, fieldName: "id", headerName: "Id", displayName: "Id" },
            { key: 1, columnsOrder: 0, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "code", headerName: "SoD Risk Code", displayName: "SoD Risk Code" },
            { key: 2, columnsOrder: 1, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "description", headerName: "SoD Risk Description", displayName: "SoD Risk Description" },
            { key: 3, columnsOrder: 2, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "firstSodActivity", headerName: "SoD Activity 1", displayName: "SoD Activity 1" },
            { key: 4, columnsOrder: 3, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "secondSodActivity", headerName: "SoD Activity 2", displayName: "SoD Activity 2" },
            { key: 5, columnsOrder: 4, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "areas", headerName: "Areas", displayName: "Areas" },
            { key: 6, columnsOrder: 5, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "impact", headerName: "Impact", displayName: "Impact" },
            { key: 7, columnsOrder: 6, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "impactApplication", headerName: "Impact Application", displayName: "Impact Application" },
            { key: 8, columnsOrder: 7, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "criticality", headerName: "Priority", displayName: "Priority" },
            { key: 9, columnsOrder: 8, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "grcRiskId", headerName: "Grc Risk Id", displayName: "Grc Risk Id" },
            { key: 10, columnsOrder: 9, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "italianDescription", headerName: "Italian Description", displayName: "Italian Description" },
            { key: 11, columnsOrder: 10, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "italianDescription", headerName: "Italian Description", displayName: "Italian Description" },
          //  { key: 9, columnsOrder: 8, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "riskImpact", headerName: "Risk Impact", displayName: "Risk Impact" },
        ],
        actualCompensatoryControlsHeadersList: [
            { key: 0, columnsOrder: -1, filter: 'blocked', hidden: 'blocked', minWidth: 50, flex: 0.8, fieldName: "id", headerName: "Id", displayName: "Id" },
            { key: 1, columnsOrder: 0, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "code", headerName: "Control Code", displayName: "Control Code" },
            { key: 2, columnsOrder: 1, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "name", headerName: "Compensatory Control Name", displayName: "Compensatory Control Name" },
            { key: 3, columnsOrder: 2, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "description", headerName: "Description", displayName: "Compensatory Control Description" },
            { key: 4, columnsOrder: 3, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "predictiveDetective", headerName: "Control Type (Preventive/Detective)", displayName: "Control Type (Preventive/Detective)" },
            { key: 5, columnsOrder: 4, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "specificMonitoring", headerName: "Control Type (Specific/Monitoring/Organizational)", displayName: "Control Type (Specific/Monitoring/Organizational)" },
            { key: 6, columnsOrder: 5, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "category", headerName: "Category", displayName: "Category" },
            { key: 7, columnsOrder: 6, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "manualAutomaticSemiautomatic", headerName: "Control Type (Manual/Automatic/Semiautomatic)", displayName: "Control Type (Manual/Automatic/Semiautomatic)" },
            { key: 8, columnsOrder: 7, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "minPeriodicity", headerName: "Min periodicity", displayName: "Min periodicity" },
            { key: 9, columnsOrder: 8, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "sodActivities", headerName: "SoD Activities", displayName: "SoD Activities" },
            { key: 10, columnsOrder: 9, filter: true, hidden: false, minWidth: 50, flex: 0.8, fieldName: "risks", headerName: "SoD Risks", displayName: "SoD Risks" },
            { key: 11, columnsOrder: 10, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "ericaCode", headerName: "Erica Code/ICF Code", displayName: "Erica Code/ICF Code" },
            { key: 12, columnsOrder: 11, filter: true, hidden: true, minWidth: 50, flex: 0.8, fieldName: "details", headerName: "Details", displayName: "Details" },
        ],
        sodObjectDependencies: [],
        tableValueOptions: []
    });
    const prevSelectionModel = React.useRef(state.selectionModel);

    const [sortModel, setSortModel] = React.useState([
        { field: '', sort: '' },
    ]);

    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(100);

    const setHiddenRows = (newVal) => {
        setState({ hiddenRows: newVal });
        if (!newVal) {
            setSelectionModel([]);
            state.filter.propertyFilters = [];
            loadRequests();
        }
    }

    const setSelectionModel = (selData) => {
        const arr = [];
        arr.push(selData);
        if (arr && arr.length > 0) {
            arr.forEach(x => {
                if (state.selectionModel.indexOf(x) === -1) {
                    state.selectionModel.push(x);
                }
            })

            if (state.selectionModel.length) {
                setHiddenRows(true);
                const newData = state.filter;

                state.selectionModel.forEach(el => {
                    if (newData.propertyFilters.filter(f => f.name === "HiddenIds" && f.value === el).length === 0) {
                        newData.propertyFilters.push(
                            {
                                value: el,
                                name: "HiddenIds",
                            }
                        );
                    }
                })
                setFilter(newData);
                loadRequests()
            }
        }
    }

    const setFilter = (newData) => {
        const oldData = state.filter;
        setState({ filter: { ...oldData, ...newData } });
    }

    const setHeaderList = (newData) => {
        setState({ actualHeadersList: [...newData] });
    }
    const setSodActivitiesHeaderList = (newData) => {
        setState({ actualSodActivitiesHeadersList: [...newData] });
    }
    const setSoDRisksHeaderList = (newData) => {
        setState({ actualSoDRisksHeadersList: [...newData] });
    }
    const setCompensatoryControlsHeaderList = (newData) => {
        setState({ actualCompensatoryControlsHeadersList: [...newData] });
    }

    const [isLoadingRequests, setIsLoadingRequests] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const loadRequests = async () => {
        try {
            setIsLoadingRequests(true);

            const r = await ApiClient.post(ApiRoutes.SoDMatrixTool.GetRequests());
            var requests = r.data; // why "requests"??
            const totalItems = requests.length;

            setState({ requests, totalItems });
            setIsLoadingRequests(false);
        } catch (e) {
            enqueueSnackbar(defaltErrorMessageLoadData);
            console.debug(e);
            trackError(e);
            setIsLoadingRequests(false);
        }
    }

    const [isLoadingSodActivities, setIsLoadingSodActivities] = useState(false);
    const loadSodActivities = async (filter) => {
        try {
            setIsLoadingSodActivities(true);

            const newHeader = state.actualSodActivitiesHeadersList;
            const r = await ApiClient.post(ApiRoutes.SoDMatrixTool.GetSodActivities());
            const items = r.data.items.filter(x => x.code !== '#No SoD Relevant')
            setSodActivitiesHeaderList(newHeader);
            setState({
                sodActivities: items,
                totalSodActivities: r.data.items.length,
                tableValueOptions: r.data.tableValueOptions,
            });
            setIsLoadingSodActivities(false);
        } catch (e) {
            enqueueSnackbar(defaltErrorMessageLoadData);
            console.debug(e);
            trackError(e);
            setIsLoadingSodActivities(false);
        }
    }

    const [isLoadingSoDRisks, setIsLoadingSoDRisks] = useState(false);
    const loadSoDRisks = async (filter) => {
        try {
            setIsLoadingSoDRisks(true);

            const newHeader = state.actualSoDRisksHeadersList;
            const r = await ApiClient.post(ApiRoutes.SoDMatrixTool.GetSoDRisks());

            const items = r.data.items.filter(x => x.code !== 'TBC');

            setSoDRisksHeaderList(newHeader);
            setState({
                soDRisks: items,
                totalSoDRisks: r.data.items.length,
                tableValueOptions: r.data.tableValueOptions,
            });
            setIsLoadingSoDRisks(false);
        } catch (e) {
            enqueueSnackbar(defaltErrorMessageLoadData);
            console.debug(e);
            trackError(e);
            setIsLoadingSoDRisks(false);
        }
    }

    const [isLoadingCompensatoryControls, setIsLoadingCompensatoryControls] = useState(false);
    const loadCompensatoryControls = async (filter) => {
        try {
            setIsLoadingCompensatoryControls(true);

            const newHeader = state.actualCompensatoryControlsHeadersList;
            const r = await ApiClient.post(ApiRoutes.SoDMatrixTool.GetCompensatoryControls());

            setCompensatoryControlsHeaderList(newHeader);
            setState({
                compensatoryControls: r.data.items,
                totalCompensatoryControls: r.data.items.length,
                tableValueOptions: r.data.tableValueOptions,
            });
            setIsLoadingCompensatoryControls(false);
        } catch (e) {
            enqueueSnackbar(defaltErrorMessageLoadData);
            console.debug(e);
            trackError(e);
            setIsLoadingCompensatoryControls(false);
        }
    }

    const [isLoadingSoDObjectDependencies, setIsLoadingSoDObjectDependencies] = useState(false);
    const loadSoDObjectDependencies = async (id, entityType) => {
        try {
            setIsLoadingSoDObjectDependencies(true);

            const r = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetDependenciesOf({
                id,
                entityType
            }));

            setState({ sodObjectDependencies: r.data });
            setIsLoadingSoDObjectDependencies(false);
        } catch (e) {
            enqueueSnackbar(defaltErrorMessageLoadData);
            console.debug(e);
            trackError(e);
            setIsLoadingSoDObjectDependencies(false);
        }
    }
    const cleanSoDObjectDependencies = () => {
        setState({ sodObjectDependencies: [] });
    }

    const [isLoadingComplexOptions, setisLoadingComplexOptions] = useState(false);
    const loadComplexOptions = async (...entityNames) => {
        try {
            setisLoadingComplexOptions(true);

            const localComplexOptions = {
                compensatoryControl: [],
                risk: [],
                sodActivity: []
            };

            await Promise.all(entityNames.map(async entityName => {
                const r = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetComplexOptions({ entityName }));

                const options = r.data;
                switch (entityName) {
                    case ComplexEntityNames.CompensatoryControl:
                        localComplexOptions.compensatoryControl = options;
                        break;
                    case ComplexEntityNames.Risk:
                        localComplexOptions.risk = options;
                        break;
                    case ComplexEntityNames.SodActivity:
                        localComplexOptions.sodActivity = options;
                        break;
                    default:
                        break;
                }
            }));

            setState({
                complexOptions: localComplexOptions
            });

            setisLoadingComplexOptions(false);

        } catch (e) {
            console.debug(e)
            trackError(e);
            setisLoadingComplexOptions(false);
        }
    }

    const [isLoadingOptions, setIsLoadingOptions] = useState(false);
    const loadOptions = async (optionType) => {
        try {
            setIsLoadingOptions(true);

            let codeQuery = "";

            switch (optionType) {
                case 'SodActivitiesGridOptions':
                    const process_s = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Process'), codeQuery }));
                    const module_s = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Area'), codeQuery }));
                    setState({
                        options: {
                            process: process_s.data,
                            module: module_s.data,
                            sodActivity: state.options.sodActivity,
                            compensatoryControl: state.options.compensatoryControl,
                            risk: state.options.risk,
                            criticality: state.options.criticality, 
                            predictiveDetective: state.options.predictiveDetective, 
                            specificMonitoring: state.options.specificMonitoring,
                            manualAutomaticSemiautomatic: state.options.manualAutomaticSemiautomatic
                        }
                    });
                    break;
                case 'CompensatoryControlsGridOptions':
                    const sodActivity_c = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('SodActivity'), codeQuery }));
                    const risks_c = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Risk'), codeQuery }));
                    setState({
                        options: {
                            process: state.options.process,
                            module: state.options.module,
                            sodActivity: sodActivity_c.data,
                            compensatoryControl: state.options.compensatoryControl,
                            risk: risks_c.data,
                            predictiveDetective: state.options.predictiveDetective,
                            criticality: state.options.criticality, 
                            specificMonitoring: state.options.specificMonitoring,
                            manualAutomaticSemiautomatic: state.options.manualAutomaticSemiautomatic
                        }
                    });
                    break;
                case 'RisksGridOptions':
                    const sodActivity_r = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('SodActivity'), codeQuery }));
                    const module_r = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Area'), codeQuery }));
                    setState({
                        options: {
                            process: state.options.process,
                            module: module_r.data,
                            sodActivity: sodActivity_r.data,
                            compensatoryControl: state.options.compensatoryControl,
                            risk: state.options.risk,
                            predictiveDetective: state.options.predictiveDetective,
                            criticality: state.options.criticality, 
                            specificMonitoring: state.options.specificMonitoring,
                            manualAutomaticSemiautomatic: state.options.manualAutomaticSemiautomatic,
                        }
                    });
                    break;
                case "BusinessProcess":
                    const process = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Process'), codeQuery }));
                    setState({
                        options: {
                            process: process.data,
                            module: state.options.module,
                            sodActivity: state.options.sodActivity,
                            compensatoryControl: state.options.compensatoryControl,
                            risk: state.options.risk,
                            predictiveDetective: state.options.predictiveDetective,
                            criticality: state.options.criticality, 
                            specificMonitoring: state.options.specificMonitoring,
                            manualAutomaticSemiautomatic: state.options.manualAutomaticSemiautomatic
                        }
                    });
                    break;
                case "ModuleSap":
                    const module = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Area'), codeQuery }));
                    setState({
                        options: {
                            process: state.options.process,
                            module: module.data,
                            sodActivity: state.options.sodActivity,
                            compensatoryControl: state.options.compensatoryControl,
                            risk: state.options.risk,
                            predictiveDetective: state.options.predictiveDetective,
                            criticality: state.options.criticality, 
                            specificMonitoring: state.options.specificMonitoring,
                            manualAutomaticSemiautomatic: state.options.manualAutomaticSemiautomatic
                        }
                    });
                    break;
                case "SodActivities":
                    const sodActivity = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('SodActivity'), codeQuery }));

                    setState({
                        options: {
                            process: state.options.process,
                            module: state.options.module,
                            sodActivity: sodActivity.data,
                            compensatoryControl: state.options.compensatoryControl,
                            risk: state.options.risk,
                            predictiveDetective: state.options.predictiveDetective,
                            criticality: state.options.criticality, 
                            specificMonitoring: state.options.specificMonitoring,
                            manualAutomaticSemiautomatic: state.options.manualAutomaticSemiautomatic
                        }
                    });
                    break;
                case "CompensatoryControl":
                    const compensatoryControls = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('CompensatoryControl'), codeQuery }));
                    setState({
                        options: {
                            module: state.options.module,
                            risk: state.options.risk,
                            sodActivity: state.options.sodActivity,
                            compensatoryControl: compensatoryControls.data,
                            process: state.options.process,
                            predictiveDetective: state.options.predictiveDetective,
                            criticality: state.options.criticality, 
                            specificMonitoring: state.options.specificMonitoring,
                            manualAutomaticSemiautomatic: state.options.manualAutomaticSemiautomatic
                        }
                    });
                    break;
                case "Risk":
                    const risks = await ApiClient.get(ApiRoutes.SoDMatrixTool.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Risk'), codeQuery }));
                    setState({
                        options: {
                            module: state.options.module,
                            risk: risks.data,
                            sodActivity: state.options.sodActivity,
                            compensatoryControl: state.options.compensatoryControl,
                            process: state.options.process,
                            predictiveDetective: state.options.predictiveDetective,
                            criticality: state.options.criticality, 
                            specificMonitoring: state.options.specificMonitoring,
                            manualAutomaticSemiautomatic: state.options.manualAutomaticSemiautomatic
                        }
                    });
                    break;
                default:
                    break;
            }

            setIsLoadingOptions(false);
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsLoadingOptions(false);
        }
    }

    const [isSearchingOption, setIsSearchingOption] = useState(false);
    const searchFiltersOption = async (optionType, text) => {
        try {
            setIsSearchingOption(true);

            let codeQuery = '';
            if (text !== undefined)
                codeQuery = text;

            switch (optionType) {
                case "BusinessProcess":
                    const process = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Process'), codeQuery }));
                    setState({
                        options: {
                            process: process.data,
                        }
                    });
                    break;
                case "ModuleSap":
                    const module = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Area'), codeQuery }));
                    setState({
                        options: {
                            module: module.data,
                        }
                    });
                    break;
                case "SodActivities":
                    const sodActivity = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('SodActivity'), codeQuery }));
                    setState({
                        options: {
                            sodActivity: sodActivity.data,
                        }
                    });
                    break;
                case "BackingTransactions":
                    const backingTransactions = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('TransactionRequest'), codeQuery }));
                    setState({
                        options: {
                            backingTransactions: backingTransactions.data
                        }
                    });
                default:
                    break;
            }
            setIsSearchingOption(false);
        } catch (e) {
            console.debug(e);
            trackError(e);
            setIsSearchingOption(false);
        }
    }

    const [pageState, setPageState] = useAggregatedState({ selectedTab: 'SoDRisks' });

    const setSelectedTab = (selTab) => {
        setPageState({ selectedTab: selTab });
    }

    const [isDeleting, setIsDeleting] = useState(false);
    const deleteSodActivity = async (id) => {
        try {
            setIsDeleting(true);
            await ApiClient.delete(ApiRoutes.SoDMatrixTool.DeleteSodActivity({ id: id }));
            setIsDeleting(false);
            enqueueSnackbar(defaultSuccessMessageDelete, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageDeleteData);
            trackError(e);
            setIsDeleting(false);
        }
    }

    const deleteRisk = async (id) => {
        try {
            setIsDeleting(true);
            await ApiClient.delete(ApiRoutes.SoDMatrixTool.DeleteRisk({ id }));
            setIsDeleting(false);
            enqueueSnackbar(defaultSuccessMessageDelete, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageDeleteData);
            trackError(e);
            setIsDeleting(false);
        }
    }

    const deleteCompensatoryControl = async (id) => {
        try {
            setIsDeleting(true);
            await ApiClient.delete(ApiRoutes.SoDMatrixTool.DeleteCompensatoryControl({ id }));
            setIsDeleting(false);
            enqueueSnackbar(defaultSuccessMessageDelete, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageDeleteData);
            trackError(e);
            setIsDeleting(false);
        }
    }

    const resolveOption = (entity, valueId) => {
        const lookupId = Number(valueId);
        if (AllAuthPlatformTableNames.includes(entity)) {
            // simple option
            return state.tableValueOptions.find(x => x.tableDefinitionName === entity
                && Number(x.tableValueId) === Number(valueId))?.value;
        }
        // complex option
        const correctedEntityName = entity.charAt(0).toLowerCase() + entity.slice(1)
        const items = state.complexOptions[correctedEntityName];
        const item = items?.find(x => x.key === lookupId);
        const value = item?.value;
        const description = item?.description;

        if (entity == ComplexEntityNames.Risk)
            return value;
        if (ComplexEntityNames.SodActivity)
            return value + ' - ' + description;
            return value;
    }

    const resolveOptions = (entity, valueIds) => {
        if (valueIds && valueIds.length > 0)
            return valueIds.map(x => resolveOption(entity, x));
        return [];
    }

    const [isUpdatingSodActivity, setIsUpdatingSodActivity] = useState(false);
    const updateSodActivity = async (request) => {
        try {
            setIsUpdatingSodActivity(true);

            const r = await ApiClient.patch(ApiRoutes.SoDMatrixTool.UpdateSodActivity(), request);
            let items = state.sodActivities;
            const index = state.sodActivities.findIndex(x => x.id === r.data.id);
            items[index] = r.data;
            setState({
                sodActivities: items
            })
            setIsUpdatingSodActivity(false);
            enqueueSnackbar(defaultSuccessMessageUpdate, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageWriteData);
            trackError(e);
            setIsUpdatingSodActivity(false);
            return false;
        }
    }

    const [isUpdatingRisk, setIsUpdatingRisk] = useState(false);
    const updateRisk = async (request) => {
        try {
            setIsUpdatingRisk(true);
            const r = await ApiClient.patch(ApiRoutes.SoDMatrixTool.UpdateRisk(), request);
            let items = state.soDRisks;
            const index = state.soDRisks.findIndex(x => x.id === r.data.id);
            items[index] = r.data;
            setState({
                soDRisks: items
            })
            setIsUpdatingRisk(false);
            enqueueSnackbar(defaultSuccessMessageUpdate, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageWriteData);
            trackError(e);
            setIsUpdatingRisk(false);
            return false;
        }
    }

    const [isUpdatingCompensatoryControls, setIsUpdatingCompensatoryControls] = useState(false);
    const updateCompensatoryControls = async (request) => {
        try {
            setIsUpdatingCompensatoryControls(true);
            const r = await ApiClient.patch(ApiRoutes.SoDMatrixTool.UpdateCompensatoryControl(), request);
            let items = state.compensatoryControls;
            const index = state.compensatoryControls.findIndex(x => x.id === r.data.id);
            items[index] = r.data;
            setState({
                compensatoryControls: items
            })
            setIsUpdatingCompensatoryControls(false);
            enqueueSnackbar(defaultSuccessMessageUpdate, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageWriteData);
            trackError(e);
            setIsUpdatingCompensatoryControls(false);
            return false;
        }
    }

    const [isAddingSodActivity, setIsAddingSodActivity] = useState(false);
    const addSodActivity = async (request) => {
        try {
            setIsAddingSodActivity(true);

            const r = await ApiClient.post(ApiRoutes.SoDMatrixTool.AddSodActivity(), request);
            let items = state.sodActivities;
            items.push(r.data);
            setState({
                sodActivities: items
            })
            setIsAddingSodActivity(false);
            enqueueSnackbar(defaultSuccessMessageAdd, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageWriteData);
            trackError(e);
            setIsAddingSodActivity(false);
            return false;
        }
    }

    const [isAddingCompensatoryControl, setIsAddingCompensatoryControl] = useState(false);
    const addCompensatoryControl = async (request) => {
        try {
            setIsAddingCompensatoryControl(true);

            const r = await ApiClient.post(ApiRoutes.SoDMatrixTool.AddCompensatoryControl(), request);
            let items = state.compensatoryControls;
            items.push(r.data);
            setState({
                compensatoryControls: items
            })
            setIsAddingCompensatoryControl(false);
            enqueueSnackbar(defaultSuccessMessageAdd, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageWriteData);
            trackError(e);
            setIsAddingCompensatoryControl(false);
            return false;
        }
    }

    const [isAddingRisk, setIsAddingRisk] = useState(false);
    const addRisk = async (request) => {
        try {
            setIsAddingRisk(true);

            const r = await ApiClient.post(ApiRoutes.SoDMatrixTool.AddRisk(), request);
            let items = state.soDRisks;
            items.push(r.data);
            setState({
                soDRisks: items
            })
            setIsAddingRisk(false);
            enqueueSnackbar(defaultSuccessMessageAdd, { variant: "success" });
        } catch (e) {
            notifyError(e, defaltErrorMessageWriteData);
            trackError(e);
            setIsAddingRisk(false);
            return false;
        }
    }

    return (
        <Provider value={{
            state,
            setState,
            isLoadingRequests,

            isLoadingSodActivities,
            isLoadingSoDRisks,
            isLoadingCompensatoryControls,

            isSearching,

            loadRequests,
            loadSodActivities,
            loadSoDRisks,
            loadCompensatoryControls,

            loadOptions,
            isLoadingOptions,
            searchFiltersOption,

            prevSelectionModel,
            setSelectionModel,
            sortModel,
            setSortModel,
            page,
            setPage,
            pageSize,
            setPageSize,
            setFilter,
            setHeaderList,

            setSodActivitiesHeaderList,
            setSoDRisksHeaderList,
            setCompensatoryControlsHeaderList,

            setHiddenRows,

            pageState,
            setPageState,
            setSelectedTab,

            loadSoDObjectDependencies,
            cleanSoDObjectDependencies,
            isLoadingSoDObjectDependencies,
            loadComplexOptions,
            isLoadingComplexOptions,

            isDeleting,
            deleteSodActivity,
            deleteRisk,
            deleteCompensatoryControl,

            resolveOption,
            resolveOptions,

            updateSodActivity,
            isUpdatingSodActivity,
            updateRisk,
            isUpdatingRisk,
            updateCompensatoryControls,
            isUpdatingCompensatoryControls,
            isUpdatingSodActivity,
            addSodActivity,
            isAddingSodActivity,
            addCompensatoryControl,
            isAddingCompensatoryControl,
            addRisk,
            isAddingRisk
        }}>
            {props.children}
        </Provider>
    );
}