import { TableCell, TableRow } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from "@mui/system";
import { DataGrid } from '@mui/x-data-grid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: { backgroundColor: theme.palette.common.black, color: theme.palette.common.white, },
    [`&.${tableCellClasses.body}`]: { fontSize: 14, },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:MuiGrid-root': { backgroundColor: theme.palette.action.hover, },
    '&:last-child td, &:last-child th': { border: 0, },
}));

export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 15,
    },
}));

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.light === 'light' ? '#f0f0f0' : '#303030'}`,
    color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-renderingZone': {
        maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
        lineHeight: 'unset !important',
        maxHeight: 'none !important',
        whiteSpace: 'normal',
    },
    '& .MuiDataGrid-row': {
        // maxHeight: 'none !important',
        // maxHeight: '81 px',
    },
    '& .data--internal-clone-element': {
        whiteSpace: 'normal',
    },
    '& .MuiDataGrid-cell--withRenderer > span': {
        whiteSpace: 'normal',
    },
    '& .MuiTablePagination-displayedRows': {
        marginTop: '15px'
    },
    '& .MuiTablePagination-selectLabel': {
        marginTop: '12px'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
        fontSize: '30 px',
    },
    '& .MuiDataGrid-columnHeader': {
        // backgroundColor: theme.palette.grey[400],
        backgroundColor: theme.palette.primary.main,
    },
    '& .MuiDataGrid-columnHeader:first-child': {
        //color: theme.palette.primary.main,
    },
    '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: theme.palette.grey[100]
    },
    '&.MuiDataGrid-root': {
        border: 'none'
    },
    '& .MuiDataGrid-columnSeparator': {
        display: 'none'
    },
    '& .contract-trigger': {
        overflow: 'none',
        boxsizing: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
        height: '40px',
        minHeight: 'none',
        borderTop: 'none',
        marginBottom: '40px'
    },
})
);