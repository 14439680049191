import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { Component, useContext } from 'react';
import { Route, Switch } from "react-router-dom";
import { loginRequest } from "./authConfig";
import { InsightProvider, UserContext, UserProvider } from "./contexts";
import { DataProvider } from './contexts/DataContext';
import './custom.css';
import { CataloguePage } from './pages/CataloguePage';
import { CompensatoryControlsDetailPage } from './pages/details/CompensatoryControlsDetailPage';
import { SoDMatrixPage } from './pages/SoDMatrixPage';
import { RisksDetailPage } from './pages/details/RisksDetailPage';
import { SoDDetailPage } from './pages/details/SoDDetailPage';
import { theme } from "./styles/theme";
import { PageLayout } from "./ui-components/page-layout";
import { configureInterceptors, NavRoutes } from "./utils";

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        configureInterceptors(this.props.pca);
    }

    render() {
        return (
            <MsalProvider instance={this.props.pca}>
                <InsightProvider>
                    <UserProvider>
                        <DataProvider>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={theme}>
                                    <AuthProvider />
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </DataProvider>
                    </UserProvider>
                </InsightProvider>
            </MsalProvider>
        );
    }
}

function AuthProvider() {
    const { inProgress, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const {
        state,
        isLogged,
        setLogged,
        setAdmin
    } = useContext(UserContext);

    const authRequest = {
        ...loginRequest
    };

    const { applicationUser } = state;
    const allowRoute = () => {

        if (!isAuthenticated) {
            instance.loginRedirect(authRequest);
        }
        if (inProgress !== "none" || !applicationUser) {
            return true;
        }
        if (applicationUser?.soDMatrixToolSpecificRoles?.length > 0) {
            setLogged(true);
            return true;
        }
        return false;
    }

    if (allowRoute()) {
        return (
            <PageLayout isLogged={isLogged} >
                <Pages setAdmin={setAdmin} isLogged={isLogged} />
            </PageLayout >
        );
    }
    return instance.loginRedirect(authRequest)
}

function Pages(props) {
    return (
        <Switch>
            <ProtectedRoute exact path={NavRoutes.SoDMatrixPage}>
                <SoDMatrixPage
                    dataGrid='SoDMatrix'
                    isLogged={props.isLogged}
                />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.RisksDetailPageTemplate} >
                <RisksDetailPage
                    dataGrid='Risks'
                    isLogged={props.isLogged}
                />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.CompensatoryControlsDetailPageTemplate} >
                <CompensatoryControlsDetailPage
                    dataGrid='CompensatoryControls'
                    isLogged={props.isLogged}
                />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.SoDDetailPageTemplate} >
                <SoDDetailPage
                    dataGrid='SodActivities'
                    isLogged={props.isLogged}
                />
            </ProtectedRoute>
            <ProtectedRoute path={NavRoutes.CataloguePage}>
                <CataloguePage
                    isLogged={props.isLogged}
                />
            </ProtectedRoute>
        </Switch>
    )
}

function ProtectedRoute(props) {
    return <Route {...props} />;
}
