import React, { useContext } from "react";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { DataContext } from '../contexts/DataContext';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


export default function ColumnsList(props) {

    const {
        state,
        setHeaderList,
        setSodActivitiesHeaderList,
        setSoDRisksHeaderList,
        setCompensatoryControlsHeaderList,
    } = useContext(DataContext);

    var list;
    switch (props.dataGrid) {
        case 'SoDMatrix':
            list = state.actualHeadersList;
            break;
        case 'SodActivities':
            list = state.actualSodActivitiesHeadersList;
            break;
        case 'Risks':
            list = state.actualSoDRisksHeadersList;
            break;
        case 'CompensatoryControls':
            list = state.actualCompensatoryControlsHeadersList;
            break;
    }

    var hide = list.filter(h => h.hidden === true);
    var view = list.filter(h => h.hidden === false);

    const handleToggle = (item) => () => {

        let objIndex;
        let newData;
        let actualList;
        switch (props.dataGrid) {
            case 'SoDMatrix':
                actualList = state.actualHeadersList;
                newData = state.actualHeadersList;
                objIndex = state.actualHeadersList.findIndex((header => header.key === item.key));
                break;
            case 'SodActivities':
                actualList = state.actualSodActivitiesHeadersList;
                newData = state.actualSodActivitiesHeadersList;
                objIndex = state.actualSodActivitiesHeadersList.findIndex((header => header.key === item.key));
                break;
            case 'Risks':
                actualList = state.actualSoDRisksHeadersList;
                newData = state.actualSoDRisksHeadersList;
                objIndex = state.actualSoDRisksHeadersList.findIndex((header => header.key === item.key));
                break;
            case 'CompensatoryControls':
                actualList = state.actualCompensatoryControlsHeadersList;
                newData = state.actualCompensatoryControlsHeadersList;
                objIndex = state.actualCompensatoryControlsHeadersList.findIndex((header => header.key === item.key));
                break;
        }

        if (item.hidden === true) {
            newData[objIndex].columnsOrder = Math.max(...actualList.map(h => h.columnsOrder)) + 1;
            newData[objIndex].hidden = false;
            setNewHeaderList(newData);
        } else {
            newData[objIndex].columnsOrder = -1;
            newData[objIndex].hidden = true;
            setNewHeaderList(newData);
        }
    };

    const setNewHeaderList = (item) => {
        switch (props.dataGrid) {
            case 'SoDMatrix':
                setHeaderList(item);
                break;
            case 'SodActivities':
                setSodActivitiesHeaderList(item);
                break;
            case 'Risks':
                setSoDRisksHeaderList(item);
                break;
            case 'CompensatoryControls':
                setCompensatoryControlsHeaderList(item);
                break;
        }
    }

    const customList = (title, items) => (
        <Card >
            <List
                sx={{
                    width: 400,
                    height: 760,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {
                    items.sort((a, b) => a.columnsOrder - b.columnsOrder).map((item) => {
                        const labelId = `transfer-list-all-item-${item.headerName}-label`;

                        return (
                            <ListItem
                                key={item.key}
                                role="listitem"
                                button
                                onClick={handleToggle(item)}
                            >
                                <ListItemText id={labelId} primary={item.headerName} />
                                <ListItemIcon>
                                    {
                                        item.hidden ?
                                            <IconButton aria-label="add" size="large">
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                            :
                                            <IconButton aria-label="delete" size="large">
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                    }
                                </ListItemIcon>
                            </ListItem>
                        );
                    })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList('Choices', view)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">

                </Grid>
            </Grid>
            <Grid item>{customList('Chosen', hide)}</Grid>
        </Grid>
    );
}
