import React from "react";
import { Paper, List, ListItem, IconButton, ListItemText, Typography, Divider } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const DependencyList = ({ dependencies, title, urlSegment }) => {
    return dependencies && dependencies.length ? <>
        {title &&
            <Typography variant="h6">{title}</Typography>
        }
        <List component={Paper} sx={{ mt: 2, backgroundColor: 'rgba(0, 0, 0, 0.02)' }}>
            {dependencies.map((dep, index) => <>
                <ListItem
                    sx={{ px: 6 }}
                    secondaryAction={<IconButton
                        sx={{ mx: 6 }}
                        edge="end"
                        component={RouterLink}
                        to={`/${urlSegment}/${dep.id}`}>
                        <OpenInNewIcon />
                    </IconButton>}
                >
                    <ListItemText primary={dep.code} />
                </ListItem>
                {index + 1 !== dependencies.length &&
                    <Divider sx={{ mx: 1 }} />
                }
            </>
            )}
        </List>
    </> : <></>;
};