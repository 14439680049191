import React, { useEffect, useState, useContext } from "react";
import { ApiClient, ApiRoutes, useAggregatedState } from "../utils";
import { InsightContext } from "./";

export const UserContext = React.createContext(null);

const { Provider } = UserContext;

export const UserProvider = (props) => {
    const { trackError } = useContext(InsightContext);
    const [state, setState] = useAggregatedState({
        applicationUser: null,
    });

    // ___________ SoDMatrixTool application user (admin, operator) ____________

    useEffect(() => {
        loadApplicationUser();
    }, [])

    const catalogueLabel = 'Catalogue';
    const sodMatrixLabel = 'FIND THE APPROPRIATE CONTROL!';

    const [userState, setUserState] = useAggregatedState({
        actualSection: "CataloguePage",
        navBarText: sodMatrixLabel,
    });

    const setNavBarTxt = (navBarText) => {
        setUserState({ navBarText });
    }

    const setActualSection = (actualSection) => {
        var nvBartxt = '';
        switch (actualSection) {
            case 'SoDDetailPage':
            case 'CompensatoryControlsDetailPage':
            case 'RisksDetailPage':
            case 'SoDMatrixPage':
                nvBartxt = catalogueLabel;
                break;
            case 'CataloguePage':
                nvBartxt = sodMatrixLabel;
                break;
            default:
                actualSection = 'SoDMatrixPage';
                nvBartxt = catalogueLabel;
                break;
        }
        setUserState({ actualSection });
        setNavBarTxt(nvBartxt);
    }

    const [isLoadingApplicationUser, setIsLoadingApplicationUser] = useState();
    const loadApplicationUser = async () => {
        try {
            setIsLoadingApplicationUser(true);

            const r = await ApiClient.get(ApiRoutes.User.GetMe());

            setState({ applicationUser: r.data });

            setIsLoadingApplicationUser(false);

        } catch (e) {
            trackError(e);
            setIsLoadingApplicationUser(false);
        }
    }

    const cleanup = () => {
        setState({
        })
    }

    const isUserInRole = (role) => state.applicationUser.soDMatrixToolSpecificRoles.some(x => x === role);
    const isUserInAnyRole = (roles) => state.applicationUser.soDMatrixToolSpecificRoles.some(role =>
        roles.some(innerRole => role === innerRole))

    const [isLogged, setLogged] = useState();

    return (
        <Provider value={{
            isLoadingApplicationUser,
            cleanup,
            userState,
            state,
            isLogged,
            setActualSection,
            setLogged,
            setNavBarTxt,
            isUserInRole,
            isUserInAnyRole
        }}>
            {props.children}
        </Provider>
    );
}