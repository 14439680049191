/* eslint-disable default-case */
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Box, IconButton
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { downloadExcelFromDatagrid, copyToClipboard } from '../../utils';
import DatagridToolbar from '../DatagridToolbar';
import { StyledDataGrid, StyledTooltip } from '../StyledTableComponents';

function getRender(field, row) {
    let text;
    let fragment = '';
    let txtToCopy;
    text = row[field];

    handleFragment();

    return {
        text,
        cell: (
            <StyledTooltip title={
                <>
                    {text}
                    <IconButton
                        onClick={() => copyToClipboard(txtToCopy ? txtToCopy : text)}
                        variant='contained'
                        size='small'>
                        <ContentCopyIcon fontSize='small' />
                    </IconButton >
                </>
            }
            >
                <span style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                    {
                        fragment ? fragment : text
                    }

                </span>
            </StyledTooltip>
        )
    };

    function handleFragment() {
        if (text) {
            fragment =
                <div style={{
                    whiteSpace: 'normal',
                    display: "-webkit-box",
                    '-webkit-line-clamp': '3',
                    '-webkit-box-orient': 'vertical',
                    overflow: "hidden"
                }}>
                    {text}
                </div>;
        }
    }
}

export default function SoDMatrixDataGrid(props) {
    const {
        state,
        setHeaderList,
        loadRequests,
        isLoadingRequests,
        sortModel,
        setSortModel,
    } = useContext(DataContext);
    var itemsCount = state.totalItems;
    const memoizedColumns = React.useMemo(() => {
        let columns = [];

        state.actualHeadersList.sort((a, b) => a.columnsOrder - b.columnsOrder).map(head => (
            columns.push({
                id: head.key,
                headerName: head.headerName,
                field: head.fieldName,
                description: head.headerName,
                headerAlign: head.headerAlign,
                flex: head.flex,
                minWidth: head.minWidth,
                hideable: true,
                hide: head.hidden,
                groupable: false,
                Tooltip,
                renderCell: (params) => (
                    getRender(params?.colDef?.field, params.row).cell
                ),
            })
        ))
        return (columns);
    }, [state.actualHeadersList]);

    const memoizedRows = React.useMemo(() => {
        let rows = [];
        let items = state.requests;

        if (items !== undefined) {

            const newHeader = state.actualHeadersList;

            if (state.filter.searchText) {
                const query = state.filter.searchText.toLowerCase();
                items = items.filter(r => r.searchableText.toLowerCase().includes(query));
            }

            state.filter.propertyFilters.forEach(f => {
                if (f.value) {
                    const fieldName = f.name.charAt(0).toLowerCase() + f.name.slice(1);
                    const idx = state.actualHeadersList.findIndex((header => header.fieldName === fieldName));
                    if (idx !== -1 && state.actualHeadersList[idx].hidden === true) {
                        newHeader[idx].columnsOrder = Math.max(...state.actualHeadersList.map(h => h.columnsOrder)) + 1;
                        newHeader[idx].hidden = false;
                    }
                    if (fieldName === 'sodActivityCode') {
                        const query = f.value.toLowerCase();
                        items = items.filter(r => r.sodActivity.toLowerCase().includes(query));
                    }
                    else {
                        const filterquery = f.value.toLowerCase();
                        items = items.filter(r => r[fieldName]?.toLowerCase().includes(filterquery));
                    }
                }
            });

            items.forEach(t => {
                const o = {};
                Object.defineProperty(o, 'internalId', {
                    value: t['id'],
                    writable: false,
                    enumerable: true
                });

                state.actualHeadersList.forEach((head) => {
                    if (head.fieldName === 'sodActivityCode' && t[head.fieldName] !== null) {
                        Object.defineProperty(o, head.fieldName, {
                            value: t[head.fieldName] + ' - ' + t['sodActivityDescription'],
                            writable: false,
                            enumerable: true
                        });
                    } else if (head.fieldName === 'ericaCode' ) {
                        let val = t[head.fieldName];
                        if (val === 'N/A - N/A' || val === undefined || val === null )
                            val = 'N/A';
                        Object.defineProperty(o, head.fieldName, {
                            value: val,
                            writable: false,
                            enumerable: true
                        });
                    } else if (head.fieldName === 'details' ) {
                        let val = t[head.fieldName];
                        if (val === '' || val === undefined || val === null)
                            val = 'N/A';
                        Object.defineProperty(o, head.fieldName, {
                            value: val,
                            writable: false,
                            enumerable: true
                        });
                    } else {
                        Object.defineProperty(o, head.fieldName, {
                            value: t[head.fieldName],
                            writable: false,
                            enumerable: true
                        });
                    }
                })
                rows.push(o);
            });
            if (newHeader !== state.actualHeadersList)
                setHeaderList(newHeader);
            itemsCount = items.length;
            // if (totalItems !== state.totalItems)
            //     setState({ requests: items, totalItems }); // NO!!! use memo va usato in modo funzionale, deve modificare e ritornare gli items filtrati, non va a cambiare la sorgente
        }
        return (rows);
    }, [state.actualHeadersList, state.requests, state.filter]);

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    };

    React.useEffect(() => {
        (
            async () => {
                await loadRequests();
            }
        )();
    }, []);

    return (
        <>
            <DatagridToolbar
                dataGrid='SoDMatrix'
                onDownload={() =>
                    downloadExcelFromDatagrid(
                        memoizedRows,
                        memoizedColumns,
                        'SoD Matrix',
                        getRender)}
                loading={isLoadingRequests}
                {...props} />
            <Box
                sx={{
                    height: 600,
                    '& .super-app-them  e--header': {
                        backgroundColor: '#787878',
                    },
                }}
            >
                <StyledDataGrid style={{ height: 600, width: '100%' }}
                    columns={memoizedColumns}
                    rows={memoizedRows}
                    rowsPerPageOptions={[100, 200, 300]}
                    disableExtendRowFullWidth={false}
                    getRowId={(row) => (row.internalId + row.riskCode + row.sodActivityCode)}
                    density={'compact'}
                    headerHeight={50}
                    rowHeight={90}
                    disableColumnMenu={true}
                    autoPageSize={false}
                    hideFooterPagination={false}
                    hideFooter={false}
                    rowCount={itemsCount}// state.totalItems}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    loading={isLoadingRequests}
                    disableSelectionOnClick
                    disableVirtualization
                    rowBuffer={0}
                />
            </Box>
        </>
    );
}